var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn clearfix px-0 pt-2 pb-4",
      class: `${
        _vm.$screen.width <= 576 ? "container-mobile" : _vm.fillClassContainer
      }`,
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _vm.$screen.width > 576
            ? _c("div", { staticClass: "card-collapsible px-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickHeader("work-details")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "title w-90" },
                      [
                        _c("b-icon", {
                          staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                          attrs: { icon: "file-earmark-person" },
                        }),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(_vm._s(_vm.FormMSG(129, "Work details"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cursor-pointer d-flex justify-content-end w-10",
                      },
                      [
                        !_vm.configAccordion["work-details"].isVisible
                          ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_DOWN.name), {
                              tag: "component",
                              attrs: {
                                color: _vm.ICONS.CHEVRON_DOWN.color,
                                size: 22,
                              },
                            })
                          : _vm._e(),
                        _vm.configAccordion["work-details"].isVisible
                          ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name), {
                              tag: "component",
                              attrs: {
                                color: _vm.ICONS.CHEVRON_UP.color,
                                size: 22,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "b-collapse",
            {
              attrs: { visible: _vm.configAccordion["work-details"].isVisible },
            },
            [
              _c(
                "div",
                {
                  class: `${
                    _vm.$screen.width > 576 ? "content-card-collapsible" : ""
                  }`,
                },
                [
                  _vm.$screen.width <= 576
                    ? _c("div", { staticClass: "card-collapsible px-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("work-info")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c("b-icon", {
                                  staticStyle: { color: "#06263e" },
                                  attrs: {
                                    icon: "file-earmark-check",
                                    "font-scale": "1.2",
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(306, "Work info"))),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["work-info"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["work-info"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible: _vm.configAccordion["work-info"].isVisible,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: `${
                            _vm.$screen.width > 576
                              ? ""
                              : "content-card-collapsible inside"
                          }`,
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: `${_vm.showCCN2642CDDU() ? 4 : 6}`,
                                    xl: `${_vm.showCCN2642CDDU() ? 4 : 6}`,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(41, "Union"),
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          disabled: _vm.isEditable() === false,
                                          options:
                                            _vm.optionsForTimeManagementType,
                                        },
                                        on: {
                                          change: _vm.handleManagementType,
                                        },
                                        model: {
                                          value:
                                            _vm.contract.timeManagementType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "timeManagementType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contract.timeManagementType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showStandard()
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              88,
                                              "Default day type"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              options:
                                                _vm.optionsForDefaultDayType,
                                            },
                                            model: {
                                              value:
                                                _vm.contract.defaultDayType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "defaultDayType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.defaultDayType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.showCCNAnnex()
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(42, "Week type"),
                                            "label-for": "timeManagementType",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              options: _vm.optionsForNumDays,
                                            },
                                            model: {
                                              value: _vm.contract.workSixDays,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "workSixDays",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.workSixDays",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.showCCN2642CDDU()
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-row col-8 px-0",
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  324,
                                                  "Work Type - CCN 2642"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options: _vm.cnn2642Options,
                                                },
                                                on: {
                                                  change: _vm.handleCcn2642,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract.workSixDays,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "workSixDays",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.workSixDays",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value:
                                                    "weeklyFiveDaysRefSalary",
                                                  expression:
                                                    "'weeklyFiveDaysRefSalary'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${_vm.FormMSG(
                                                  327,
                                                  "35h week reference salary"
                                                )}`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "weeklyFiveDaysRefSalary",
                                                        expression:
                                                          "'weeklyFiveDaysRefSalary'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "text",
                                                      placeholder: "0",
                                                    },
                                                    on: {
                                                      update:
                                                        _vm.handleWeeklyRefSalary,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .weeklyFiveDaysRefSalary,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "weeklyFiveDaysRefSalary",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.weeklyFiveDaysRefSalary",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.showCCN2642CDICDD()
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value:
                                                "cdiMonthlyReferenceSalary",
                                              expression:
                                                "'cdiMonthlyReferenceSalary'",
                                            },
                                          ],
                                          attrs: {
                                            label: `${_vm.FormMSG(
                                              328,
                                              "Monthly reference salary"
                                            )}`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value:
                                                      "cdiMonthlyReferenceSalary",
                                                    expression:
                                                      "'cdiMonthlyReferenceSalary'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "number",
                                                  step: "0.000001",
                                                  min: "0",
                                                  placeholder: "0",
                                                },
                                                on: {
                                                  update:
                                                    _vm.handleMonthlyRefSalary,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .cdiMonthlyReferenceSalary,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "cdiMonthlyReferenceSalary",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.cdiMonthlyReferenceSalary",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#06263E",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "card-collapsible px-0",
                      class: `${_vm.$screen.width > 576 ? "mt-1" : "mt-4"}`,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleClickHeader("main-details")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "title w-90" },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.LIST_CHECK.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.LIST_CHECK.color,
                                  size: 22,
                                },
                              }),
                              _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(130, "Main details")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer d-flex justify-content-end w-10",
                            },
                            [
                              !_vm.configAccordion["main-details"].isVisible
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHEVRON_DOWN.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.CHEVRON_DOWN.color,
                                        size: 22,
                                      },
                                    }
                                  )
                                : _vm._e(),
                              _vm.configAccordion["main-details"].isVisible
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHEVRON_UP.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.CHEVRON_UP.color,
                                        size: 22,
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible: _vm.configAccordion["main-details"].isVisible,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible inside" },
                        [
                          _vm.showStandard()
                            ? _c(
                                "div",
                                { staticClass: "px-0" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "hourlyRate",
                                                  expression: "'hourlyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        89,
                                                        "Hourly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        13,
                                                        "Hourly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "hourlyRate",
                                                        expression:
                                                          "'hourlyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.computeSMG(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.hourlyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "hourlyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.hourlyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "dailyRate",
                                                  expression: "'dailyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        11,
                                                        "Daily Rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        90,
                                                        "Daily rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "dailyRate",
                                                        expression:
                                                          "'dailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.dailyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "dailyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.dailyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "weeklyRate",
                                                  expression: "'weeklyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        310,
                                                        "Weekly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        123,
                                                        "Weekly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "weeklyRate",
                                                        expression:
                                                          "'weeklyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.computeHourlyRate(
                                                          _vm.contract
                                                            .weeklyFiveDaysMinimumSalary
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .weeklyFiveDaysMinimumSalary,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "weeklyFiveDaysMinimumSalary",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.weeklyFiveDaysMinimumSalary",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "kmRate",
                                                  expression: "'kmRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        311,
                                                        "Monthly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        303,
                                                        "Monthly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "monthlyRate",
                                                        expression:
                                                          "'monthlyRate'",
                                                      },
                                                    ],
                                                    ref: "kmRate",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useMonthlyRate ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .monthlyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "monthlyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.monthlyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minDay",
                                                  expression: "'minDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        24,
                                                        "Daily Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        92,
                                                        "Daily hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          24,
                                                          "Daily Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          92,
                                                          "Daily hours"
                                                        )
                                                  }`,
                                                  uiid: "minDay",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName: "totMinPerDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minWeek",
                                                  expression: "'minWeek'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        25,
                                                        "Weekly Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        91,
                                                        "Weekly hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          25,
                                                          "Weekly Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          91,
                                                          "Weekly hours"
                                                        )
                                                  }`,
                                                  uiid: "minWeek",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerWeekStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.weekly
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.weekly
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName: "totMinPerWeek",
                                                  workType: _vm.WORKTYPE.weekly,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minDayForCont",
                                                  expression: "'minDayForCont'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        312,
                                                        "Daily Work for continuous day"
                                                      )
                                                    : _vm.FormMSG(
                                                        295,
                                                        "Daily hours for continuous day"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          312,
                                                          "Daily Work for continuous day"
                                                        )
                                                      : _vm.FormMSG(
                                                          295,
                                                          "Daily hours for continuous day"
                                                        )
                                                  }`,
                                                  uiid: "minDayForCont",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerDayForContDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName:
                                                    "totMinPerDayForContDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minHourGuarantee",
                                                  expression:
                                                    "'minHourGuarantee'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width > 576
                                                    ? _vm.FormMSG(
                                                        167,
                                                        "Minimum hour guarantee"
                                                      )
                                                    : _vm.FormMSG(
                                                        307,
                                                        "Min hour guarantee"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width > 576
                                                      ? _vm.FormMSG(
                                                          167,
                                                          "Minimum hour guarantee"
                                                        )
                                                      : _vm.FormMSG(
                                                          307,
                                                          "Min hour guarantee"
                                                        )
                                                  }`,
                                                  uiid: "minHourGuarantee",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .minimumHourGuaranteedStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.contract
                                                        .minimumHourGuaranteedType ===
                                                      0
                                                        ? _vm.WORKTYPE.daily
                                                        : _vm.WORKTYPE.weekly
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.contract
                                                        .minimumHourGuaranteedType ===
                                                      0
                                                        ? _vm.WORKTYPE.daily
                                                        : _vm.WORKTYPE.weekly
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName:
                                                    "minimumHourGuaranteed",
                                                  workType:
                                                    _vm.contract
                                                      .minimumHourGuaranteedType ===
                                                    0
                                                      ? _vm.WORKTYPE.daily
                                                      : _vm.WORKTYPE.weekly,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "minHourGuaranteeType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.partialPeriodicalOptions,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleGuaranteedType,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .minimumHourGuaranteedType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "minimumHourGuaranteedType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.minimumHourGuaranteedType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "travelDailyRate",
                                                  expression:
                                                    "'travelDailyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${_vm.FormMSG(
                                                  12,
                                                  "Travel rate "
                                                )}`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "travelDailyRate",
                                                        expression:
                                                          "'travelDailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .travelDailyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "travelDailyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.travelDailyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showDefaultPayCode
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      320,
                                                      "Pay code daily rate"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "baseSalary1PayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .baseSalary1PayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "baseSalary1PayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "baseSalary1PayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      321,
                                                      "Pay code continuous day"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "baseSalary2PayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .baseSalary2PayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "baseSalary2PayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "baseSalary2PayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      322,
                                                      "Pay code travel day"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "baseSalary3PayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .baseSalary3PayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "baseSalary3PayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "baseSalary3PayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      323,
                                                      "Pay code hourly rate"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "baseSalary4PayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .baseSalary4PayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "baseSalary4PayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "baseSalary4PayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm.showCCNAnnex1()
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "hrate",
                                                  expression: "'hrate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        59,
                                                        "Hourly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        308,
                                                        "Hourly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "hrate",
                                                        expression: "'hrate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.computeSMG(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.hourlyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "hourlyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.hourlyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.contract.workSixDays === 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "smg",
                                                      expression: "'smg'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: `${
                                                      _vm.$screen.width <= 576
                                                        ? _vm.FormMSG(
                                                            143,
                                                            "Minimum salary (SMG)"
                                                          )
                                                        : _vm.FormMSG(
                                                            61,
                                                            "Minimum salary (SMG)"
                                                          )
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "smg",
                                                            expression: "'smg'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: "",
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBaseRefSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBaseRefSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBaseRefSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "smg",
                                                      expression: "'smg'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: `${
                                                      _vm.$screen.width <= 576
                                                        ? _vm.FormMSG(
                                                            43,
                                                            "Five days minimum salary (SMG)"
                                                          )
                                                        : _vm.FormMSG(
                                                            62,
                                                            "5 days minimum salary (SMG)"
                                                          )
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "smg",
                                                            expression: "'smg'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: "",
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                          formatter:
                                                            _vm.roundNumeric,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyFiveDaysMinimumSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyFiveDaysMinimumSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyFiveDaysMinimumSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 2
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "sdsmg",
                                                      expression: "'sdsmg'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: `${
                                                      _vm.$screen.width <= 576
                                                        ? _vm.FormMSG(
                                                            47,
                                                            "Six days minimum salary (SMG) €:"
                                                          )
                                                        : _vm.FormMSG(
                                                            65,
                                                            "6 days minimum salary (SMG)"
                                                          )
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "sdsmg",
                                                            expression:
                                                              "'sdsmg'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: "",
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.computeHourlyRate(
                                                              _vm.contract
                                                                .weeklySixDaysMinimumSalary
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklySixDaysMinimumSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklySixDaysMinimumSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklySixDaysMinimumSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "travelDailyRate",
                                                  expression:
                                                    "'travelDailyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${_vm.FormMSG(
                                                  12,
                                                  "Travel rate "
                                                )}`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "travelDailyRate",
                                                        expression:
                                                          "'travelDailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .travelDailyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "travelDailyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.travelDailyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                            xl: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minDay",
                                                  expression: "'minDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        24,
                                                        "Daily Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        104,
                                                        "Daily hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          24,
                                                          "Daily Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          104,
                                                          "Daily hours"
                                                        )
                                                  }`,
                                                  uiid: "minDay",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName: "totMinPerDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                            xl: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _vm.contract.workSixDays === 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "minWeek",
                                                      expression: "'minWeek'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: `${
                                                      _vm.$screen.width <= 576
                                                        ? _vm.FormMSG(
                                                            25,
                                                            "Weekly Work"
                                                          )
                                                        : _vm.FormMSG(
                                                            125,
                                                            "Weekly hours"
                                                          )
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              25,
                                                              "Weekly Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              125,
                                                              "Weekly hours"
                                                            )
                                                      }`,
                                                      uiid: "minWeek",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .totMinPerWeekStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "totMinPerWeek",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "fdeh",
                                                      expression: "'fdeh'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      63,
                                                      "5 days effective hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        63,
                                                        "5 days effective hours"
                                                      ),
                                                      uiid: "fdeh",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklyFiveDaysEffNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklyFiveDaysEffNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 2
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "minWeek",
                                                      expression: "'minWeek'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      127,
                                                      "6 days effective hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        127,
                                                        "6 days effective hours"
                                                      ),
                                                      uiid: "fdeh",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklySixDaysEffNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklySixDaysEffNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.contract.workSixDays > 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "4",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _vm.contract.workSixDays === 1
                                                ? _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value: "fdeq",
                                                          expression: "'fdeq'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          64,
                                                          "5 days equivalent hours"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "input-date-time-picker",
                                                        {
                                                          attrs: {
                                                            label: _vm.FormMSG(
                                                              64,
                                                              "5 days equivalent hours"
                                                            ),
                                                            uiid: "fdeq",
                                                            type: "text",
                                                            placeholder:
                                                              "hh:mm",
                                                            isSubmitted:
                                                              _vm.isSubmitted,
                                                            item: _vm.contract
                                                              .weeklyFiveDaysEquNumHoursStr,
                                                            nbHour:
                                                              _vm.rangeHour[
                                                                _vm.WORKTYPE
                                                                  .weekly
                                                              ],
                                                            nbMinute:
                                                              _vm.rangeMinute[
                                                                _vm.WORKTYPE
                                                                  .weekly
                                                              ],
                                                            isEditable: () =>
                                                              _vm.isEditable(),
                                                            fieldName:
                                                              "weeklyFiveDaysEquNumHours",
                                                            workType:
                                                              _vm.WORKTYPE
                                                                .weekly,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleChangeHourMin,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contract.workSixDays === 2
                                                ? _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value: "fdeq",
                                                          expression: "'fdeq'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          128,
                                                          "6 days equivalent hours"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "input-date-time-picker",
                                                        {
                                                          attrs: {
                                                            label: _vm.FormMSG(
                                                              128,
                                                              "6 days equivalent hours"
                                                            ),
                                                            uiid: "fdeq",
                                                            type: "text",
                                                            placeholder:
                                                              "hh:mm",
                                                            isSubmitted:
                                                              _vm.isSubmitted,
                                                            item: _vm.contract
                                                              .weeklySixDaysEquNumHoursStr,
                                                            nbHour:
                                                              _vm.rangeHour[
                                                                _vm.WORKTYPE
                                                                  .weekly
                                                              ],
                                                            nbMinute:
                                                              _vm.rangeMinute[
                                                                _vm.WORKTYPE
                                                                  .weekly
                                                              ],
                                                            isEditable: () =>
                                                              _vm.isEditable(),
                                                            fieldName:
                                                              "weeklySixDaysEquNumHours",
                                                            workType:
                                                              _vm.WORKTYPE
                                                                .weekly,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleChangeHourMin,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.showCCNAnnex3()
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "hourlyRateA3",
                                                  expression: "'hourlyRateA3'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        68,
                                                        "Hourly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        309,
                                                        "Hourly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "hourlyRateA3",
                                                        expression:
                                                          "'hourlyRateA3'",
                                                      },
                                                    ],
                                                    class: {
                                                      "is-invalid":
                                                        _vm.isSubmitted &&
                                                        _vm.$v.contract
                                                          .hourlyRateAnnexThree
                                                          .$error,
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.computeSMG()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .hourlyRateAnnexThree,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "hourlyRateAnnexThree",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.hourlyRateAnnexThree",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.isSubmitted &&
                                              _vm.$v.contract
                                                .hourlyRateAnnexThree.$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              150,
                                                              "Please, value must be greater than 0"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "travelDailyRate",
                                                  expression:
                                                    "'travelDailyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${_vm.FormMSG(
                                                  12,
                                                  "Travel rate "
                                                )}`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "travelDailyRate",
                                                        expression:
                                                          "'travelDailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .travelDailyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "travelDailyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.travelDailyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.contract.workSixDays === 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value:
                                                        "weeklyBonusAmount",
                                                      expression:
                                                        "'weeklyBonusAmount'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      69,
                                                      "Weekly bonus"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "weeklyBonusAmount",
                                                            expression:
                                                              "'weeklyBonusAmount'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBonusAmount",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value:
                                                        "weeklyBonusAmountFiveDays",
                                                      expression:
                                                        "'weeklyBonusAmountFiveDays'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      72,
                                                      "Weekly bonus"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "weeklyBonusAmountFiveDays",
                                                            expression:
                                                              "'weeklyBonusAmountFiveDays'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBonusAmountFiveDays,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBonusAmountFiveDays",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBonusAmountFiveDays",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 2
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value:
                                                        "weeklyBonusAmountSixDays",
                                                      expression:
                                                        "'weeklyBonusAmountSixDays'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      77,
                                                      "Weekly bonus"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "weeklyBonusAmountSixDays",
                                                            expression:
                                                              "'weeklyBonusAmountSixDays'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBonusAmountSixDays,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBonusAmountSixDays",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBonusAmountSixDays",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.contract.workSixDays === 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbms",
                                                      expression: "'wbms'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      70,
                                                      "Min. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbms",
                                                            expression:
                                                              "'wbms'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBaseMinimumSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBaseMinimumSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBaseMinimumSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbms",
                                                      expression: "'wbms'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      73,
                                                      "5 days min. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbms",
                                                            expression:
                                                              "'wbms'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyFiveDaysMinimumSalaryAnThree,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyFiveDaysMinimumSalaryAnThree",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyFiveDaysMinimumSalaryAnThree",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 2
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbms",
                                                      expression: "'wbms'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      78,
                                                      "6 days min. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbms",
                                                            expression:
                                                              "'wbms'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklySixDaysMinimumSalaryAnThree,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklySixDaysMinimumSalaryAnThree",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklySixDaysMinimumSalaryAnThree",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.contract.workSixDays === 0
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbrf",
                                                      expression: "'wbrf'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      71,
                                                      "Ref. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbrf",
                                                            expression:
                                                              "'wbrf'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyBaseRefSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyBaseRefSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyBaseRefSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbrf",
                                                      expression: "'wbrf'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      76,
                                                      "5 days ref. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbrf",
                                                            expression:
                                                              "'wbrf'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklyFiveDaysRefSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklyFiveDaysRefSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklyFiveDaysRefSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.contract.workSixDays === 2
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "wbrf",
                                                      expression: "'wbrf'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      81,
                                                      "6 days ref. salary"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value: "wbrf",
                                                            expression:
                                                              "'wbrf'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled: true,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .weeklySixDaysRefSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "weeklySixDaysRefSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.weeklySixDaysRefSalary",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                            xl: `${
                                              _vm.contract.workSixDays === 0
                                                ? 6
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minDay",
                                                  expression: "'minDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        24,
                                                        "Daily Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        104,
                                                        "Daily hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          24,
                                                          "Daily Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          104,
                                                          "Daily hours"
                                                        )
                                                  }`,
                                                  uiid: "minDay",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName: "totMinPerDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.contract.workSixDays === 0
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: `${
                                                  _vm.contract.workSixDays === 0
                                                    ? 6
                                                    : 4
                                                }`,
                                                xl: `${
                                                  _vm.contract.workSixDays === 0
                                                    ? 6
                                                    : 4
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "minWeek",
                                                      expression: "'minWeek'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: `${
                                                      _vm.$screen.width <= 576
                                                        ? _vm.FormMSG(
                                                            25,
                                                            "Weekly Work"
                                                          )
                                                        : _vm.FormMSG(
                                                            91,
                                                            "Weekly hours"
                                                          )
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              25,
                                                              "Weekly Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              91,
                                                              "Weekly hours"
                                                            )
                                                      }`,
                                                      uiid: "minWeek",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .totMinPerWeekStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "totMinPerWeek",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.contract.workSixDays === 1
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "4",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "fdeh",
                                                      expression: "'fdeh'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      74,
                                                      "5 days effective hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        74,
                                                        "5 days effective hours"
                                                      ),
                                                      uiid: "fdeh",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklyFiveDaysEffNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklyFiveDaysEffNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.contract.workSixDays === 2
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "4",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "fdeh",
                                                      expression: "'fdeh'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      79,
                                                      "6 days effective hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        79,
                                                        "6 days effective hours"
                                                      ),
                                                      uiid: "fdeh",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklySixDaysEffNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklySixDaysEffNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.contract.workSixDays === 1
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "4",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "fdeq",
                                                      expression: "'fdeq'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: this.FormMSG(
                                                      75,
                                                      "5 days equivalent hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: this.FormMSG(
                                                        75,
                                                        "5 days equivalent hours"
                                                      ),
                                                      uiid: "fdeq",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklyFiveDaysEquNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklyFiveDaysEquNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.contract.workSixDays === 2
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "4",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "fdeq",
                                                      expression: "'fdeq'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: this.FormMSG(
                                                      80,
                                                      "6 days equivalent hours"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("input-date-time-picker", {
                                                    attrs: {
                                                      label: this.FormMSG(
                                                        80,
                                                        "6 days equivalent hours"
                                                      ),
                                                      uiid: "fdeq",
                                                      type: "text",
                                                      placeholder: "hh:mm",
                                                      isSubmitted:
                                                        _vm.isSubmitted,
                                                      item: _vm.contract
                                                        .weeklySixDaysEquNumHoursStr,
                                                      nbHour:
                                                        _vm.rangeHour[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      nbMinute:
                                                        _vm.rangeMinute[
                                                          _vm.WORKTYPE.weekly
                                                        ],
                                                      isEditable: () =>
                                                        _vm.isEditable(),
                                                      fieldName:
                                                        "weeklySixDaysEquNumHours",
                                                      workType:
                                                        _vm.WORKTYPE.weekly,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeHourMin,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.showCCN2642CDICDD()
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "hourlyRate",
                                                  expression: "'hourlyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        89,
                                                        "Hourly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        13,
                                                        "Hourly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "hourlyRate",
                                                        expression:
                                                          "'hourlyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled: "",
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.computeSMG(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.hourlyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "hourlyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.hourlyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "dailyRate",
                                                  expression: "'dailyRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        11,
                                                        "Daily Rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        90,
                                                        "Daily rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "dailyRate",
                                                        expression:
                                                          "'dailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled: "",
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.dailyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "dailyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.dailyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 4
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "kmRate",
                                                  expression: "'kmRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        311,
                                                        "Monthly rate"
                                                      )
                                                    : _vm.FormMSG(
                                                        303,
                                                        "Monthly rate"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "monthlyRate",
                                                        expression:
                                                          "'monthlyRate'",
                                                      },
                                                    ],
                                                    ref: "kmRate",
                                                    attrs: {
                                                      disabled: "",
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                      formatter:
                                                        _vm.roundNumeric,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .monthlyRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "monthlyRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.monthlyRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minDay",
                                                  expression: "'minDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        24,
                                                        "Daily Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        92,
                                                        "Daily hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  disabled: "",
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          24,
                                                          "Daily Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          92,
                                                          "Daily hours"
                                                        )
                                                  }`,
                                                  uiid: "minDay",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () => {
                                                    return false
                                                  },
                                                  fieldName: "totMinPerDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 6
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 6 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "minWeek",
                                                  expression: "'minWeek'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${
                                                  _vm.$screen.width <= 576
                                                    ? _vm.FormMSG(
                                                        25,
                                                        "Weekly Work"
                                                      )
                                                    : _vm.FormMSG(
                                                        91,
                                                        "Weekly hours"
                                                      )
                                                }`,
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: `${
                                                    _vm.$screen.width <= 576
                                                      ? _vm.FormMSG(
                                                          25,
                                                          "Weekly Work"
                                                        )
                                                      : _vm.FormMSG(
                                                          91,
                                                          "Weekly hours"
                                                        )
                                                  }`,
                                                  uiid: "minWeek",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .totMinPerWeekStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.weekly
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.weekly
                                                    ],
                                                  isEditable: () => {
                                                    return false
                                                  },
                                                  fieldName: "totMinPerWeek",
                                                  workType: _vm.WORKTYPE.weekly,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.showCCN2642CDDU()
                            ? _c("div", [
                                _vm.showCNN2624DailyBase()
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "hourlyRate",
                                                        expression:
                                                          "'hourlyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              89,
                                                              "Hourly rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              13,
                                                              "Hourly rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "hourlyRate",
                                                              expression:
                                                                "'hourlyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.computeSMG(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .hourlyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "hourlyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.hourlyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "dailyRate",
                                                        expression:
                                                          "'dailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              11,
                                                              "Daily Rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              90,
                                                              "Daily rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "dailyRate",
                                                              expression:
                                                                "'dailyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .dailyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "dailyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.dailyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "minDay",
                                                        expression: "'minDay'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              24,
                                                              "Daily Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              92,
                                                              "Daily hours"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "input-date-time-picker",
                                                      {
                                                        attrs: {
                                                          label: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? _vm.FormMSG(
                                                                  24,
                                                                  "Daily Work"
                                                                )
                                                              : _vm.FormMSG(
                                                                  92,
                                                                  "Daily hours"
                                                                )
                                                          }`,
                                                          uiid: "minDay",
                                                          type: "text",
                                                          placeholder: "hh:mm",
                                                          isSubmitted:
                                                            _vm.isSubmitted,
                                                          item: _vm.contract
                                                            .totMinPerDayStr,
                                                          nbHour:
                                                            _vm.rangeHour[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          nbMinute:
                                                            _vm.rangeMinute[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          isEditable: () => {
                                                            return false
                                                          },
                                                          fieldName:
                                                            "totMinPerDay",
                                                          workType:
                                                            _vm.WORKTYPE.daily,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeHourMin,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showCNN2624WeeklyBase()
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "hourlyRate",
                                                        expression:
                                                          "'hourlyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              89,
                                                              "Hourly rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              13,
                                                              "Hourly rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "hourlyRate",
                                                              expression:
                                                                "'hourlyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.computeSMG(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .hourlyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "hourlyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.hourlyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "dailyRate",
                                                        expression:
                                                          "'dailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              11,
                                                              "Daily Rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              90,
                                                              "Daily rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "dailyRate",
                                                              expression:
                                                                "'dailyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .dailyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "dailyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.dailyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "minDay",
                                                        expression: "'minDay'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              24,
                                                              "Daily Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              92,
                                                              "Daily hours"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "input-date-time-picker",
                                                      {
                                                        attrs: {
                                                          label: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? _vm.FormMSG(
                                                                  24,
                                                                  "Daily Work"
                                                                )
                                                              : _vm.FormMSG(
                                                                  92,
                                                                  "Daily hours"
                                                                )
                                                          }`,
                                                          uiid: "minDay",
                                                          type: "text",
                                                          placeholder: "hh:mm",
                                                          isSubmitted:
                                                            _vm.isSubmitted,
                                                          item: _vm.contract
                                                            .totMinPerDayStr,
                                                          nbHour:
                                                            _vm.rangeHour[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          nbMinute:
                                                            _vm.rangeMinute[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          isEditable: () => {
                                                            return false
                                                          },
                                                          fieldName:
                                                            "totMinPerDay",
                                                          workType:
                                                            _vm.WORKTYPE.daily,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeHourMin,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 6
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 6
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "weeklyRate",
                                                        expression:
                                                          "'weeklyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              326,
                                                              "Tarif semaine (info)"
                                                            )
                                                          : _vm.FormMSG(
                                                              325,
                                                              "Tarif semaine (info)"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "weeklyRate",
                                                              expression:
                                                                "'weeklyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .weeklyFiveDaysMinimumSalary,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "weeklyFiveDaysMinimumSalary",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.weeklyFiveDaysMinimumSalary",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 6
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 6
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "minWeek",
                                                        expression: "'minWeek'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              25,
                                                              "Weekly Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              91,
                                                              "Weekly hours"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "input-date-time-picker",
                                                      {
                                                        attrs: {
                                                          label: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? _vm.FormMSG(
                                                                  25,
                                                                  "Weekly Work"
                                                                )
                                                              : _vm.FormMSG(
                                                                  91,
                                                                  "Weekly hours"
                                                                )
                                                          }`,
                                                          uiid: "minWeek",
                                                          type: "text",
                                                          placeholder: "hh:mm",
                                                          isSubmitted:
                                                            _vm.isSubmitted,
                                                          item: _vm.contract
                                                            .totMinPerWeekStr,
                                                          nbHour:
                                                            _vm.rangeHour[
                                                              _vm.WORKTYPE
                                                                .weekly
                                                            ],
                                                          nbMinute:
                                                            _vm.rangeMinute[
                                                              _vm.WORKTYPE
                                                                .weekly
                                                            ],
                                                          isEditable: () => {
                                                            return false
                                                          },
                                                          fieldName:
                                                            "totMinPerWeek",
                                                          workType:
                                                            _vm.WORKTYPE.weekly,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeHourMin,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showCNN2624MonthlyBase()
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "hourlyRate",
                                                        expression:
                                                          "'hourlyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              89,
                                                              "Hourly rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              13,
                                                              "Hourly rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "hourlyRate",
                                                              expression:
                                                                "'hourlyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.computeSMG(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .hourlyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "hourlyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.hourlyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "dailyRate",
                                                        expression:
                                                          "'dailyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              11,
                                                              "Daily Rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              90,
                                                              "Daily rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "dailyRate",
                                                              expression:
                                                                "'dailyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .dailyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "dailyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.dailyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "minDay",
                                                        expression: "'minDay'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              24,
                                                              "Daily Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              92,
                                                              "Daily hours"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "input-date-time-picker",
                                                      {
                                                        attrs: {
                                                          label: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? _vm.FormMSG(
                                                                  24,
                                                                  "Daily Work"
                                                                )
                                                              : _vm.FormMSG(
                                                                  92,
                                                                  "Daily hours"
                                                                )
                                                          }`,
                                                          uiid: "minDay",
                                                          type: "text",
                                                          placeholder: "hh:mm",
                                                          isSubmitted:
                                                            _vm.isSubmitted,
                                                          item: _vm.contract
                                                            .totMinPerDayStr,
                                                          nbHour:
                                                            _vm.rangeHour[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          nbMinute:
                                                            _vm.rangeMinute[
                                                              _vm.WORKTYPE.daily
                                                            ],
                                                          isEditable: () => {
                                                            return false
                                                          },
                                                          fieldName:
                                                            "totMinPerDay",
                                                          workType:
                                                            _vm.WORKTYPE.daily,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeHourMin,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "weeklyRate",
                                                        expression:
                                                          "'weeklyRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              326,
                                                              "Tarif semaine (info)"
                                                            )
                                                          : _vm.FormMSG(
                                                              325,
                                                              "Tarif semaine (info)"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "weeklyRate",
                                                              expression:
                                                                "'weeklyRate'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .weeklyFiveDaysMinimumSalary,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "weeklyFiveDaysMinimumSalary",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.weeklyFiveDaysMinimumSalary",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "minWeek",
                                                        expression: "'minWeek'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              25,
                                                              "Weekly Work"
                                                            )
                                                          : _vm.FormMSG(
                                                              91,
                                                              "Weekly hours"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "input-date-time-picker",
                                                      {
                                                        attrs: {
                                                          label: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? _vm.FormMSG(
                                                                  25,
                                                                  "Weekly Work"
                                                                )
                                                              : _vm.FormMSG(
                                                                  91,
                                                                  "Weekly hours"
                                                                )
                                                          }`,
                                                          uiid: "minWeek",
                                                          type: "text",
                                                          placeholder: "hh:mm",
                                                          isSubmitted:
                                                            _vm.isSubmitted,
                                                          item: _vm.contract
                                                            .totMinPerWeekStr,
                                                          nbHour:
                                                            _vm.rangeHour[
                                                              _vm.WORKTYPE
                                                                .weekly
                                                            ],
                                                          nbMinute:
                                                            _vm.rangeMinute[
                                                              _vm.WORKTYPE
                                                                .weekly
                                                            ],
                                                          isEditable: () => {
                                                            return false
                                                          },
                                                          fieldName:
                                                            "totMinPerWeek",
                                                          workType:
                                                            _vm.WORKTYPE.weekly,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChangeHourMin,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  md: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 12
                                                  }`,
                                                  lg: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  xl: `${
                                                    _vm.$screen.width <= 576
                                                      ? 0
                                                      : 4
                                                  }`,
                                                  cols: `${
                                                    _vm.$screen.width <= 576
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "uni-for",
                                                        rawName: "v-uni-for",
                                                        value: "kmRate",
                                                        expression: "'kmRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label: `${
                                                        _vm.$screen.width <= 576
                                                          ? _vm.FormMSG(
                                                              311,
                                                              "Monthly rate"
                                                            )
                                                          : _vm.FormMSG(
                                                              303,
                                                              "Monthly rate"
                                                            )
                                                      }`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          directives: [
                                                            {
                                                              name: "uni-id",
                                                              rawName:
                                                                "v-uni-id",
                                                              value:
                                                                "monthlyRate",
                                                              expression:
                                                                "'monthlyRate'",
                                                            },
                                                          ],
                                                          ref: "kmRate",
                                                          attrs: {
                                                            disabled: "",
                                                            type: "number",
                                                            step: "0.000001",
                                                            min: "0",
                                                            placeholder: "0",
                                                            formatter:
                                                              _vm.roundNumeric,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contract
                                                                .monthlyRate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contract,
                                                                "monthlyRate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contract.monthlyRate",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              [
                                                                _c(
                                                                  "currency-svg",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#06263E",
                                                                      opacity:
                                                                        "0.85",
                                                                      width:
                                                                        "15",
                                                                      height:
                                                                        "15",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _vm.showOvt ||
                  _vm.showOvt1 ||
                  _vm.showOvt2 ||
                  _vm.showWeeklyOvt ||
                  _vm.showWeeklyOvt1 ||
                  _vm.showWeeklyOvt2
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("overtimes")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.ALARM_PLUS.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.ALARM_PLUS.color,
                                      size: 22,
                                    },
                                  }
                                ),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(131, "Overtimes"))),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["overtimes"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["overtimes"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showOvt ||
                  _vm.showOvt1 ||
                  _vm.showOvt2 ||
                  _vm.showWeeklyOvt ||
                  _vm.showWeeklyOvt1 ||
                  _vm.showWeeklyOvt2
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible: _vm.configAccordion["overtimes"].isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _vm.showStandard()
                                ? _c("div", [
                                    _vm.showOvt || _vm.showOvt1 || _vm.showOvt2
                                      ? _c(
                                          "div",
                                          { staticClass: "label-layout pb-3" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "pj-cb-plus-2 cursor-pointer",
                                                attrs: {
                                                  size: "lg",
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract.dailyOvertime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "dailyOvertime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.dailyOvertime",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        137,
                                                        "Daily overtimes"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showOvt || _vm.showOvt1 || _vm.showOvt2
                                      ? _c(
                                          "div",
                                          [
                                            _vm.contract.dailyOvertime
                                              ? _c(
                                                  "b-row",
                                                  [
                                                    _vm.showOvt
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: `${
                                                                _vm.showOvt1 &&
                                                                _vm.showOvt2
                                                                  ? 4
                                                                  : !_vm.showOvt1 &&
                                                                    !_vm.showOvt2
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                              xl: `${
                                                                _vm.showOvt1 &&
                                                                _vm.showOvt2
                                                                  ? 4
                                                                  : !_vm.showOvt1 &&
                                                                    !_vm.showOvt2
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "card-inside",
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    staticClass:
                                                                      "card-inside",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            132,
                                                                            "Daily overtime 1"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    attrs: {
                                                                      "align-h":
                                                                        "around",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex p-0 m-0",
                                                                        class:
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          576
                                                                            ? "flex-column justify-content-center"
                                                                            : "flex-row",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimit1",
                                                                                  expression:
                                                                                    "'ovtLimit1'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    298,
                                                                                    "After"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "uni-for",
                                                                                      rawName:
                                                                                        "v-uni-for",
                                                                                      value:
                                                                                        "ovtLimit1",
                                                                                      expression:
                                                                                        "'ovtLimit1'",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.FormMSG(
                                                                                        298,
                                                                                        "After"
                                                                                      ),
                                                                                    uiid: "ovtLimit1",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimitStr,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimit",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimitContinuousDay",
                                                                                  expression:
                                                                                    "'ovtLimitContinuousDay'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label: `${
                                                                                  _vm
                                                                                    .$screen
                                                                                    .width <=
                                                                                  576
                                                                                    ? _vm.FormMSG(
                                                                                        302,
                                                                                        "After (cont. day)"
                                                                                      )
                                                                                    : _vm.FormMSG(
                                                                                        299,
                                                                                        "After (continuous day)"
                                                                                      )
                                                                                }`,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label: `${
                                                                                      _vm
                                                                                        .$screen
                                                                                        .width <=
                                                                                      576
                                                                                        ? _vm.FormMSG(
                                                                                            302,
                                                                                            "After (cont. day)"
                                                                                          )
                                                                                        : _vm.FormMSG(
                                                                                            299,
                                                                                            "After (continuous day)"
                                                                                          )
                                                                                    }`,
                                                                                    uiid: "ovtLimitContinuousDay",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimitContinuousDayStr,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimitContinuousDay",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-column justify-content-center p-0 m-0",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "overtimeRate1",
                                                                                  expression:
                                                                                    "'overtimeRate1'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    95,
                                                                                    "Overtime rate"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "uni-id",
                                                                                          rawName:
                                                                                            "v-uni-id",
                                                                                          value:
                                                                                            "overtimeRate1",
                                                                                          expression:
                                                                                            "'overtimeRate1'",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isEditable() ===
                                                                                          false,
                                                                                        type: "number",
                                                                                        step: "0.000001",
                                                                                        min: "0",
                                                                                        placeholder:
                                                                                          "0",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .overtimeRate,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.contract,
                                                                                              "overtimeRate",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "contract.overtimeRate",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .PERCENT
                                                                                                    .color,
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm.showDefaultPayCode
                                                                          ? _c(
                                                                              "b-form-group",
                                                                              [
                                                                                _vm
                                                                                  .$screen
                                                                                  .width >
                                                                                992
                                                                                  ? _c(
                                                                                      "label",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            for: "dayOvt1PayCode",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "payroll-code",
                                                                                  {
                                                                                    ref: _vm.generateTextId(
                                                                                      "dayOvt1PayCode"
                                                                                    ),
                                                                                    attrs:
                                                                                      {
                                                                                        "style-type":
                                                                                          "select",
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .dayOvt1PayCode,
                                                                                      },
                                                                                    on: {
                                                                                      "payroll-code:input":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt1PayCode"
                                                                                          )
                                                                                        },
                                                                                      "payroll-code:reset":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.resetPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt1PayCode"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.showOvt1
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: `${
                                                                _vm.showOvt &&
                                                                _vm.showOvt2
                                                                  ? 4
                                                                  : !_vm.showOvt &&
                                                                    !_vm.showOvt2
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                              xl: `${
                                                                _vm.showOvt &&
                                                                _vm.showOvt2
                                                                  ? 4
                                                                  : !_vm.showOvt &&
                                                                    !_vm.showOvt2
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "card-inside",
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    staticClass:
                                                                      "card-inside",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            133,
                                                                            "Daily overtime 2"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    attrs: {
                                                                      "align-h":
                                                                        "around",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex p-0 m-0",
                                                                        class:
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          576
                                                                            ? "flex-column justify-content-center"
                                                                            : "flex-row",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimit2",
                                                                                  expression:
                                                                                    "'ovtLimit2'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    298,
                                                                                    "After"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.FormMSG(
                                                                                        298,
                                                                                        "After"
                                                                                      ),
                                                                                    uiid: "ovtLimit2",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimit1Str,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimit1",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimitContinuousDay1",
                                                                                  expression:
                                                                                    "'ovtLimitContinuousDay1'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label: `${
                                                                                  _vm
                                                                                    .$screen
                                                                                    .width <=
                                                                                  576
                                                                                    ? _vm.FormMSG(
                                                                                        302,
                                                                                        "After (cont. day)"
                                                                                      )
                                                                                    : _vm.FormMSG(
                                                                                        299,
                                                                                        "After (continuous day)"
                                                                                      )
                                                                                }`,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label: `${
                                                                                      _vm
                                                                                        .$screen
                                                                                        .width <=
                                                                                      576
                                                                                        ? _vm.FormMSG(
                                                                                            302,
                                                                                            "After (cont. day)"
                                                                                          )
                                                                                        : _vm.FormMSG(
                                                                                            299,
                                                                                            "After (continuous day)"
                                                                                          )
                                                                                    }`,
                                                                                    uiid: "ovtLimitContinuousDay1",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimitContinuousDay1Str,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimitContinuousDay1",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-column justify-content-center p-0 m-0",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "overtimeRate2",
                                                                                  expression:
                                                                                    "'overtimeRate2'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    97,
                                                                                    "Overtime rate"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "uni-id",
                                                                                          rawName:
                                                                                            "v-uni-id",
                                                                                          value:
                                                                                            "overtimeRate2",
                                                                                          expression:
                                                                                            "'overtimeRate2'",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isEditable() ===
                                                                                          false,
                                                                                        type: "number",
                                                                                        step: "0.000001",
                                                                                        min: "0",
                                                                                        placeholder:
                                                                                          "0",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .ovtRate1,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.contract,
                                                                                              "ovtRate1",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "contract.ovtRate1",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .PERCENT
                                                                                                    .color,
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm.showDefaultPayCode
                                                                          ? _c(
                                                                              "b-form-group",
                                                                              [
                                                                                _vm
                                                                                  .$screen
                                                                                  .width >
                                                                                992
                                                                                  ? _c(
                                                                                      "label",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            for: "dayOvt2PayCode",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "payroll-code",
                                                                                  {
                                                                                    ref: _vm.generateTextId(
                                                                                      "dayOvt2PayCode"
                                                                                    ),
                                                                                    attrs:
                                                                                      {
                                                                                        "style-type":
                                                                                          "select",
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .dayOvt2PayCode,
                                                                                      },
                                                                                    on: {
                                                                                      "payroll-code:input":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt2PayCode"
                                                                                          )
                                                                                        },
                                                                                      "payroll-code:reset":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.resetPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt2PayCode"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.showOvt2
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: `${
                                                                _vm.showOvt1 &&
                                                                _vm.showOvt
                                                                  ? 4
                                                                  : !_vm.showOvt1 &&
                                                                    !_vm.showOvt
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                              xl: `${
                                                                _vm.showOvt1 &&
                                                                _vm.showOvt
                                                                  ? 4
                                                                  : !_vm.showOvt1 &&
                                                                    !_vm.showOvt
                                                                  ? 12
                                                                  : 6
                                                              }`,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                staticClass:
                                                                  "card-inside",
                                                              },
                                                              [
                                                                _c(
                                                                  "legend",
                                                                  {
                                                                    staticClass:
                                                                      "card-inside",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            134,
                                                                            "Daily overtime 3"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    attrs: {
                                                                      "align-h":
                                                                        "around",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex p-0 m-0",
                                                                        class:
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          576
                                                                            ? "flex-column justify-content-center"
                                                                            : "flex-row",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimit3",
                                                                                  expression:
                                                                                    "'ovtLimit3'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    298,
                                                                                    "After"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.FormMSG(
                                                                                        298,
                                                                                        "After"
                                                                                      ),
                                                                                    uiid: "ovtLimit3",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimit2Str,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimit2",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "ovtLimitContinuousDay2",
                                                                                  expression:
                                                                                    "'ovtLimitContinuousDay2'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label: `${
                                                                                  _vm
                                                                                    .$screen
                                                                                    .width <=
                                                                                  576
                                                                                    ? _vm.FormMSG(
                                                                                        302,
                                                                                        "After (cont. day)"
                                                                                      )
                                                                                    : _vm.FormMSG(
                                                                                        299,
                                                                                        "After (continuous day)"
                                                                                      )
                                                                                }`,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input-date-time-picker",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label: `${
                                                                                      _vm
                                                                                        .$screen
                                                                                        .width <=
                                                                                      576
                                                                                        ? _vm.FormMSG(
                                                                                            302,
                                                                                            "After (cont. day)"
                                                                                          )
                                                                                        : _vm.FormMSG(
                                                                                            299,
                                                                                            "After (continuous day)"
                                                                                          )
                                                                                    }`,
                                                                                    uiid: "ovtLimitContinuousDay2",
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "hh:mm",
                                                                                    isSubmitted:
                                                                                      _vm.isSubmitted,
                                                                                    item: _vm
                                                                                      .contract
                                                                                      .ovtLimitContinuousDay2Str,
                                                                                    nbHour:
                                                                                      _vm
                                                                                        .rangeHour[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    nbMinute:
                                                                                      _vm
                                                                                        .rangeMinute[
                                                                                        _vm
                                                                                          .WORKTYPE
                                                                                          .daily
                                                                                      ],
                                                                                    isEditable:
                                                                                      () =>
                                                                                        _vm.isEditable(),
                                                                                    fieldName:
                                                                                      "ovtLimitContinuousDay2",
                                                                                    workType:
                                                                                      _vm
                                                                                        .WORKTYPE
                                                                                        .daily,
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.handleChangeHourMin,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-column justify-content-center p-0 m-0",
                                                                        staticStyle:
                                                                          {
                                                                            gap: "5px",
                                                                          },
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "12",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "uni-for",
                                                                                  rawName:
                                                                                    "v-uni-for",
                                                                                  value:
                                                                                    "overtimeRate3",
                                                                                  expression:
                                                                                    "'overtimeRate3'",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    136,
                                                                                    "Overtime rate"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "uni-id",
                                                                                          rawName:
                                                                                            "v-uni-id",
                                                                                          value:
                                                                                            "overtimeRate3",
                                                                                          expression:
                                                                                            "'overtimeRate3'",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isEditable() ===
                                                                                          false,
                                                                                        type: "number",
                                                                                        step: "0.000001",
                                                                                        min: "0",
                                                                                        placeholder:
                                                                                          "0",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .ovtRate2,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.contract,
                                                                                              "ovtRate2",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "contract.ovtRate2",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .name
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .PERCENT
                                                                                                    .color,
                                                                                                size: 16,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm.showDefaultPayCode
                                                                          ? _c(
                                                                              "b-form-group",
                                                                              [
                                                                                _vm
                                                                                  .$screen
                                                                                  .width >
                                                                                992
                                                                                  ? _c(
                                                                                      "label",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            for: "dayOvt3PayCode",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "payroll-code",
                                                                                  {
                                                                                    ref: _vm.generateTextId(
                                                                                      "dayOvt3PayCode"
                                                                                    ),
                                                                                    attrs:
                                                                                      {
                                                                                        "style-type":
                                                                                          "select",
                                                                                        value:
                                                                                          _vm
                                                                                            .contract
                                                                                            .dayOvt3PayCode,
                                                                                      },
                                                                                    on: {
                                                                                      "payroll-code:input":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt3PayCode"
                                                                                          )
                                                                                        },
                                                                                      "payroll-code:reset":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.resetPayroolCodeValue(
                                                                                            $event,
                                                                                            "dayOvt3PayCode"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              (_vm.showOvt || _vm.showOvt1 || _vm.showOvt2) &&
                              _vm.showStandard()
                                ? _c("div", { staticClass: "divider" })
                                : _vm._e(),
                              _vm.showWeeklyOvt ||
                              _vm.showWeeklyOvt1 ||
                              _vm.showWeeklyOvt2
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "label-layout pb-3",
                                      class: `${
                                        (_vm.showOvt ||
                                          _vm.showOvt1 ||
                                          _vm.showOvt2) &&
                                        (_vm.showWeeklyOvt ||
                                          _vm.showWeeklyOvt1 ||
                                          _vm.showWeeklyOvt2) &&
                                        !_vm.showStandard()
                                          ? "mt-4"
                                          : ""
                                      }`,
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass:
                                            "pj-cb-plus-2 cursor-pointer",
                                          attrs: {
                                            size: "lg",
                                            disabled:
                                              _vm.isEditable() === false,
                                          },
                                          model: {
                                            value: _vm.contract.weeklyOvertime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contract,
                                                "weeklyOvertime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contract.weeklyOvertime",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  138,
                                                  "Weekly overtimes"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showWeeklyOvt ||
                              _vm.showWeeklyOvt1 ||
                              _vm.showWeeklyOvt2
                                ? _c(
                                    "div",
                                    [
                                      _vm.contract.weeklyOvertime
                                        ? _c(
                                            "b-row",
                                            [
                                              _vm.showWeeklyOvt
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        sm: "12",
                                                        md: "12",
                                                        lg: `${
                                                          _vm.showWeeklyOvt1 &&
                                                          _vm.showWeeklyOvt2
                                                            ? 4
                                                            : !_vm.showWeeklyOvt1 &&
                                                              !_vm.showWeeklyOvt2
                                                            ? 12
                                                            : 6
                                                        }`,
                                                        xl: `${
                                                          _vm.showWeeklyOvt1 &&
                                                          _vm.showWeeklyOvt2
                                                            ? 4
                                                            : !_vm.showWeeklyOvt1 &&
                                                              !_vm.showWeeklyOvt2
                                                            ? 12
                                                            : 6
                                                        }`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "card-inside",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "card-inside",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      139,
                                                                      "Weekly overtime 1"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "weeklyOvtLimit1",
                                                                            expression:
                                                                              "'weeklyOvtLimit1'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            152,
                                                                            "Overtime after"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input-date-time-picker",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  152,
                                                                                  "Overtime after"
                                                                                ),
                                                                              uiid: "weeklyOvtLimit1",
                                                                              type: "text",
                                                                              placeholder:
                                                                                "hh:mm",
                                                                              isSubmitted:
                                                                                _vm.isSubmitted,
                                                                              item: _vm
                                                                                .contract
                                                                                .weeklyOvtOneLimitStr,
                                                                              nbHour:
                                                                                _vm
                                                                                  .rangeHour[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              nbMinute:
                                                                                _vm
                                                                                  .rangeMinute[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              isEditable:
                                                                                () =>
                                                                                  _vm.isEditable(),
                                                                              fieldName:
                                                                                "weeklyOvtOneLimit",
                                                                              workType:
                                                                                _vm
                                                                                  .WORKTYPE
                                                                                  .weekly,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleChangeHourMin,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "weeklyOvertimeRate1",
                                                                            expression:
                                                                              "'weeklyOvertimeRate1'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            153,
                                                                            "Overtime rate"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "uni-id",
                                                                                    rawName:
                                                                                      "v-uni-id",
                                                                                    value:
                                                                                      "weeklyOvertimeRate1",
                                                                                    expression:
                                                                                      "'weeklyOvertimeRate1'",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isEditable() ===
                                                                                    false,
                                                                                  type: "number",
                                                                                  step: "0.000001",
                                                                                  min: "0",
                                                                                  placeholder:
                                                                                    "0",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.computeSMG()
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weeklyOvtOneRate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.contract,
                                                                                        "weeklyOvtOneRate",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "contract.weeklyOvtOneRate",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .PERCENT
                                                                                        .name
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .color,
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.showDefaultPayCode
                                                            ? _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          992
                                                                            ? _c(
                                                                                "label",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      for: "weekOvt1PayCode",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "payroll-code",
                                                                            {
                                                                              ref: _vm.generateTextId(
                                                                                "weekOvt1PayCode"
                                                                              ),
                                                                              attrs:
                                                                                {
                                                                                  "style-type":
                                                                                    "select",
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weekOvt1PayCode,
                                                                                },
                                                                              on: {
                                                                                "payroll-code:input":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt1PayCode"
                                                                                    )
                                                                                  },
                                                                                "payroll-code:reset":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.resetPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt1PayCode"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showWeeklyOvt1
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        sm: "12",
                                                        md: "12",
                                                        lg: `${
                                                          _vm.showWeeklyOvt &&
                                                          _vm.showWeeklyOvt2
                                                            ? 4
                                                            : !_vm.showWeeklyOvt &&
                                                              !_vm.showWeeklyOvt2
                                                            ? 12
                                                            : 6
                                                        }`,
                                                        xl: `${
                                                          _vm.showWeeklyOvt &&
                                                          _vm.showWeeklyOvt2
                                                            ? 4
                                                            : !_vm.showWeeklyOvt &&
                                                              !_vm.showWeeklyOvt2
                                                            ? 12
                                                            : 6
                                                        }`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "card-inside",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "card-inside",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      161,
                                                                      "Weekly overtime 2"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "weeklyOvtLimit2",
                                                                            expression:
                                                                              "'weeklyOvtLimit2'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            156,
                                                                            "Overtime after"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input-date-time-picker",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  156,
                                                                                  "Overtime after"
                                                                                ),
                                                                              uiid: "weeklyOvtLimit2",
                                                                              type: "text",
                                                                              placeholder:
                                                                                "hh:mm",
                                                                              isSubmitted:
                                                                                _vm.isSubmitted,
                                                                              item: _vm
                                                                                .contract
                                                                                .weeklyOvtTwoLimitStr,
                                                                              nbHour:
                                                                                _vm
                                                                                  .rangeHour[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              nbMinute:
                                                                                _vm
                                                                                  .rangeMinute[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              isEditable:
                                                                                () =>
                                                                                  _vm.isEditable(),
                                                                              fieldName:
                                                                                "weeklyOvtTwoLimit",
                                                                              workType:
                                                                                _vm
                                                                                  .WORKTYPE
                                                                                  .weekly,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleChangeHourMin,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "weeklyOvertimeRate2",
                                                                            expression:
                                                                              "'weeklyOvertimeRate2'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            157,
                                                                            "Overtime rate"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "uni-id",
                                                                                    rawName:
                                                                                      "v-uni-id",
                                                                                    value:
                                                                                      "weeklyOvertimeRate2",
                                                                                    expression:
                                                                                      "'weeklyOvertimeRate2'",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isEditable() ===
                                                                                    false,
                                                                                  type: "number",
                                                                                  step: "0.000001",
                                                                                  min: "0",
                                                                                  placeholder:
                                                                                    "0",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.computeSMG()
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weeklyOvtTwoRate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.contract,
                                                                                        "weeklyOvtTwoRate",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "contract.weeklyOvtTwoRate",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .PERCENT
                                                                                        .name
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .color,
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.showDefaultPayCode
                                                            ? _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          992
                                                                            ? _c(
                                                                                "label",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      for: "weekOvt2PayCode",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "payroll-code",
                                                                            {
                                                                              ref: _vm.generateTextId(
                                                                                "weekOvt2PayCode"
                                                                              ),
                                                                              attrs:
                                                                                {
                                                                                  "style-type":
                                                                                    "select",
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weekOvt2PayCode,
                                                                                },
                                                                              on: {
                                                                                "payroll-code:input":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt2PayCode"
                                                                                    )
                                                                                  },
                                                                                "payroll-code:reset":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.resetPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt2PayCode"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showWeeklyOvt2
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        sm: "12",
                                                        md: "12",
                                                        lg: `${
                                                          _vm.showWeeklyOvt1 &&
                                                          _vm.showWeeklyOvt
                                                            ? 4
                                                            : !_vm.showWeeklyOvt1 &&
                                                              !_vm.showWeeklyOvt
                                                            ? 12
                                                            : 6
                                                        }`,
                                                        xl: `${
                                                          _vm.showWeeklyOvt1 &&
                                                          _vm.showWeeklyOvt
                                                            ? 4
                                                            : !_vm.showWeeklyOvt1 &&
                                                              !_vm.showWeeklyOvt
                                                            ? 12
                                                            : 6
                                                        }`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "card-inside",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "card-inside",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      158,
                                                                      "Weekly overtime 3"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "weeklyOvtLimit3",
                                                                            expression:
                                                                              "'weeklyOvtLimit3'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            159,
                                                                            "Overtime after"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input-date-time-picker",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  159,
                                                                                  "Overtime after"
                                                                                ),
                                                                              uiid: "weeklyOvtLimit3",
                                                                              type: "text",
                                                                              placeholder:
                                                                                "hh:mm",
                                                                              isSubmitted:
                                                                                _vm.isSubmitted,
                                                                              item: _vm
                                                                                .contract
                                                                                .weeklyOvtThreeLimitStr,
                                                                              nbHour:
                                                                                _vm
                                                                                  .rangeHour[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              nbMinute:
                                                                                _vm
                                                                                  .rangeMinute[
                                                                                  _vm
                                                                                    .WORKTYPE
                                                                                    .weekly
                                                                                ],
                                                                              isEditable:
                                                                                () =>
                                                                                  _vm.isEditable(),
                                                                              fieldName:
                                                                                "weeklyOvtThreeLimit",
                                                                              workType:
                                                                                _vm
                                                                                  .WORKTYPE
                                                                                  .weekly,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleChangeHourMin,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "uni-for",
                                                                            rawName:
                                                                              "v-uni-for",
                                                                            value:
                                                                              "overtimeRate3",
                                                                            expression:
                                                                              "'overtimeRate3'",
                                                                          },
                                                                        ],
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            160,
                                                                            "Overtime rate"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "uni-id",
                                                                                    rawName:
                                                                                      "v-uni-id",
                                                                                    value:
                                                                                      "overtimeRate3",
                                                                                    expression:
                                                                                      "'overtimeRate3'",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isEditable() ===
                                                                                    false,
                                                                                  type: "number",
                                                                                  step: "0.000001",
                                                                                  min: "0",
                                                                                  placeholder:
                                                                                    "0",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.computeSMG()
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weeklyOvtThreeRate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.contract,
                                                                                        "weeklyOvtThreeRate",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "contract.weeklyOvtThreeRate",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "b-input-group-append",
                                                                            [
                                                                              _c(
                                                                                "b-input-group-text",
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .PERCENT
                                                                                        .name
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .PERCENT
                                                                                              .color,
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.showDefaultPayCode
                                                            ? _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _vm
                                                                            .$screen
                                                                            .width >
                                                                          992
                                                                            ? _c(
                                                                                "label",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      for: "weekOvt3PayCode",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "payroll-code",
                                                                            {
                                                                              ref: _vm.generateTextId(
                                                                                "weekOvt3PayCode"
                                                                              ),
                                                                              attrs:
                                                                                {
                                                                                  "style-type":
                                                                                    "select",
                                                                                  value:
                                                                                    _vm
                                                                                      .contract
                                                                                      .weekOvt3PayCode,
                                                                                },
                                                                              on: {
                                                                                "payroll-code:input":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt3PayCode"
                                                                                    )
                                                                                  },
                                                                                "payroll-code:reset":
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.resetPayroolCodeValue(
                                                                                      $event,
                                                                                      "weekOvt3PayCode"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showNightHours
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("night-time")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.MOON.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.MOON.color,
                                    size: 22,
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(162, "Night Time"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["night-time"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["night-time"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showNightHours
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible:
                              _vm.configAccordion["night-time"].isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width <= 576 ? 0 : 6
                                        }`,
                                        md: `${
                                          _vm.$screen.width <= 576 ? 0 : 6
                                        }`,
                                        lg: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        xl: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        cols: `${
                                          _vm.$screen.width <= 576 ? 6 : 0
                                        }`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "nightStart",
                                              expression: "'nightStart'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              163,
                                              "Night time start"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "nightStart",
                                                expression: "'nightStart'",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              type: "time",
                                              value:
                                                _vm.contract.nightTimeStart,
                                              placeholder: "hh:mm",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.updateNightStart(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width <= 576 ? 0 : 6
                                        }`,
                                        md: `${
                                          _vm.$screen.width <= 576 ? 0 : 6
                                        }`,
                                        lg: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        xl: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        cols: `${
                                          _vm.$screen.width <= 576 ? 6 : 0
                                        }`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "nightEnd",
                                              expression: "'nightEnd'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              164,
                                              "Night time end"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "nightEnd",
                                                expression: "'nightEnd'",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              type: "time",
                                              value: _vm.contract.nightTimeEnd,
                                              placeholder: "hh:mm",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.updateNightEnd(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width <= 576 ? 0 : 12
                                        }`,
                                        md: `${
                                          _vm.$screen.width <= 576 ? 0 : 12
                                        }`,
                                        lg: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        xl: `${
                                          _vm.$screen.width <= 576 ? 0 : 3
                                        }`,
                                        cols: `${
                                          _vm.$screen.width <= 576 ? 12 : 0
                                        }`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "nightRate",
                                              expression: "'nightRate'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              165,
                                              "Night hours rate"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "nightRate",
                                                        expression:
                                                          "'nightRate'",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "border-radius":
                                                        "8px 0 0 8px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .nightTimeRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "nightTimeRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.nightTimeRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("percent", {
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.showDefaultPayCode
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "info-message fix-info-message-per-diem ml-1",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("info", {
                                                            attrs: { size: 16 },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "label",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#00a09c",
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.calculateAverageRate(
                                                                  _vm.contract
                                                                    .hourlyRate,
                                                                  _vm.contract
                                                                    .nightTimeRate
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.currencySymbol
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  191,
                                                                  "per hour"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showStandard()
                                    ? _c(
                                        "b-col",
                                        {
                                          class: `${
                                            _vm.showDefaultPayCode
                                              ? ""
                                              : "d-flex align-items-center"
                                          }`,
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576 ? 0 : 12
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 3
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 12 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          !_vm.showDefaultPayCode
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "info-message fix-info-message-per-diem",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("info", {
                                                        attrs: { size: 16 },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#00a09c",
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.calculateAverageRate(
                                                              _vm.contract
                                                                .hourlyRate,
                                                              _vm.contract
                                                                .nightTimeRate
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.currencySymbol
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              191,
                                                              "per hour"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "w-100",
                                                  style: `margin:${
                                                    _vm.$screen.width <= 992
                                                      ? "0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _vm.$screen.width > 992
                                                        ? _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                for: "nightTimePayCode",
                                                              },
                                                            },
                                                            [_vm._v(" ")]
                                                          )
                                                        : _vm._e(),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "nightTimePayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .nightTimePayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "nightTimePayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "nightTimePayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showTooEarlyHours
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("rest-time")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.SUNMOON.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.SUNMOON.color,
                                    size: 22,
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(166, "Rest Time"))),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["rest-time"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["rest-time"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showTooEarlyHours
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible: _vm.configAccordion["rest-time"].isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "6",
                                        md: "6",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "minTimeBetweenDays",
                                              expression:
                                                "'minTimeBetweenDays'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              22,
                                              "Min time between days"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "minTimeBetweenDays",
                                                expression:
                                                  "'minTimeBetweenDays'",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              type: "time",
                                              value:
                                                _vm.contract.minTimeBetweenDays,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.updateMinTimeBetweenDays(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "6",
                                        md: "6",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "minBetW",
                                              expression: "'minBetW'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              149,
                                              "Min time between weeks"
                                            ),
                                          },
                                        },
                                        [
                                          _c("input-date-time-picker", {
                                            attrs: {
                                              label: this.FormMSG(
                                                149,
                                                "Min time between weeks"
                                              ),
                                              uiid: "minBetW",
                                              type: "text",
                                              placeholder: "hh:mm",
                                              isSubmitted: _vm.isSubmitted,
                                              item: _vm.contract
                                                .minTimeBetweenWeekStr,
                                              nbHour:
                                                _vm.rangeHour[
                                                  _vm.WORKTYPE.weekly
                                                ],
                                              nbMinute:
                                                _vm.rangeMinute[
                                                  _vm.WORKTYPE.weekly
                                                ],
                                              isEditable: () =>
                                                _vm.isEditable(),
                                              fieldName: "minTimeBetweenWeek",
                                              workType: _vm.WORKTYPE.weekly,
                                            },
                                            on: {
                                              change: _vm.handleChangeHourMin,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "6",
                                        md: "6",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "anticRate",
                                              expression: "'anticRate'",
                                            },
                                          ],
                                          attrs: {
                                            label: this.FormMSG(
                                              16,
                                              "Aniticipated hours rate"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "anticRate",
                                                        expression:
                                                          "'anticRate'",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "border-radius":
                                                        "8px 0 0 8px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .hourBeforeTimeRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "hourBeforeTimeRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.hourBeforeTimeRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("percent", {
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.showDefaultPayCode
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "info-message fix-info-message-per-diem ml-1",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("info", {
                                                            attrs: { size: 16 },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "label",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#00a09c",
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.calculateAverageRate(
                                                                  _vm.contract
                                                                    .hourlyRate,
                                                                  _vm.contract
                                                                    .hourBeforeTimeRate
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.currencySymbol
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  191,
                                                                  "per hour"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showStandard()
                                    ? _c(
                                        "b-col",
                                        {
                                          class: `${
                                            _vm.showDefaultPayCode
                                              ? ""
                                              : "d-flex align-items-center"
                                          }`,
                                          attrs: {
                                            sm: "6",
                                            md: "6",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          !_vm.showDefaultPayCode
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "info-message fix-info-message-per-diem",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("info", {
                                                        attrs: { size: 16 },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#00a09c",
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.calculateAverageRate(
                                                              _vm.contract
                                                                .hourlyRate,
                                                              _vm.contract
                                                                .hourBeforeTimeRate
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.currencySymbol
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              191,
                                                              "per hour"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "w-100",
                                                  style: `margin:${
                                                    _vm.$screen.width <= 576
                                                      ? "0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _vm.$screen.width > 576
                                                        ? _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                for: "restPayCode",
                                                              },
                                                            },
                                                            [_vm._v(" ")]
                                                          )
                                                        : _vm._e(),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "restPayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .restPayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "restPayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "restPayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showTransport
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("transport")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.BUS.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.BUS.color,
                                    size: 22,
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(169, "Transport"))),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["transport"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["transport"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showTransport
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible: _vm.configAccordion["transport"].isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "6",
                                        md: "6",
                                        lg: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                        xl: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "transportPaid",
                                              expression: "'transportPaid'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              146,
                                              "Paid if above"
                                            ),
                                          },
                                        },
                                        [
                                          _c("input-date-time-picker", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                146,
                                                "Paid if above"
                                              ),
                                              uiid: "transportPaid",
                                              type: "text",
                                              placeholder: "hh:mm",
                                              isSubmitted: _vm.isSubmitted,
                                              item: _vm.contract
                                                .transportPaidAfterStr,
                                              nbHour:
                                                _vm.rangeHour[
                                                  _vm.WORKTYPE.daily
                                                ],
                                              nbMinute:
                                                _vm.rangeMinute[
                                                  _vm.WORKTYPE.daily
                                                ],
                                              isEditable: () =>
                                                _vm.isEditable(),
                                              fieldName: "transportPaidAfter",
                                              workType: _vm.WORKTYPE.daily,
                                            },
                                            on: {
                                              change: _vm.handleChangeHourMin,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "6",
                                        md: "6",
                                        lg: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                        xl: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "transportRate",
                                              expression: "'transportRate'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              147,
                                              "Amount (/h)"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "transportRate",
                                                    expression:
                                                      "'transportRate'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "number",
                                                  step: "0.000001",
                                                  min: "0",
                                                  placeholder: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract.transportRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "transportRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.transportRate",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#06263E",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showDefaultPayCode
                                    ? _c(
                                        "b-col",
                                        {
                                          style: `margin:${
                                            _vm.$screen.width <= 992
                                              ? "0"
                                              : "6px 0 0 0"
                                          }`,
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.showDefaultPayCode
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _vm.$screen.width > 992
                                                    ? _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "transportPayCode",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    : _vm._e(),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "transportPayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .transportPayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "transportPayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "transportPayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showSixthDayRate ||
                  _vm.showSeventhDayRate ||
                  _vm.showSundayRate
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader(
                                  "special-days-rates"
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.STAR.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.STAR.color,
                                    size: 22,
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(170, "Special days rates")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["special-days-rates"]
                                  .isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["special-days-rates"]
                                  .isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showSixthDayRate ||
                  _vm.showSeventhDayRate ||
                  _vm.showSundayRate
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible:
                              _vm.configAccordion["special-days-rates"]
                                .isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "b-row",
                                [
                                  _vm.showSundayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              (!_vm.showSixthDayRate &&
                                                !_vm.showSeventhDayRate) ||
                                              (!_vm.showSixthDayRate &&
                                                _vm.showSeventhDayRate) ||
                                              (_vm.showSixthDayRate &&
                                                !_vm.showSeventhDayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                            xl: `${
                                              (!_vm.showSixthDayRate &&
                                                !_vm.showSeventhDayRate) ||
                                              (!_vm.showSixthDayRate &&
                                                _vm.showSeventhDayRate) ||
                                              (_vm.showSixthDayRate &&
                                                !_vm.showSeventhDayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "sundayRate",
                                                  expression: "'sundayRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  102,
                                                  "Sunday and public holiday rate"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "sundayRate",
                                                        expression:
                                                          "'sundayRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .sundayPublicHolidayFactor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "sundayPublicHolidayFactor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.sundayPublicHolidayFactor",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("percent", {
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showDefaultPayCode && _vm.showSundayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          style: `margin:${
                                            _vm.$screen.width <= 992
                                              ? "0"
                                              : "6px 0 0 0"
                                          }`,
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _vm.$screen.width > 992
                                                ? _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "sundayPayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  )
                                                : _vm._e(),
                                              _c("payroll-code", {
                                                ref: _vm.generateTextId(
                                                  "sundayPayCode"
                                                ),
                                                attrs: {
                                                  "style-type": "select",
                                                  value:
                                                    _vm.contract.sundayPayCode,
                                                },
                                                on: {
                                                  "payroll-code:input":
                                                    function ($event) {
                                                      return _vm.setPayroolCodeValue(
                                                        $event,
                                                        "sundayPayCode"
                                                      )
                                                    },
                                                  "payroll-code:reset":
                                                    function ($event) {
                                                      return _vm.resetPayroolCodeValue(
                                                        $event,
                                                        "sundayPayCode"
                                                      )
                                                    },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showSixthDayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              (!_vm.showSundayRate &&
                                                !_vm.showSeventhDayRate) ||
                                              (!_vm.showSundayRate &&
                                                _vm.showSeventhDayRate) ||
                                              (_vm.showSundayRate &&
                                                !_vm.showSeventhDayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                            xl: `${
                                              (!_vm.showSundayRate &&
                                                !_vm.showSeventhDayRate) ||
                                              (!_vm.showSundayRate &&
                                                _vm.showSeventhDayRate) ||
                                              (_vm.showSundayRate &&
                                                !_vm.showSeventhDayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "sixthDay",
                                                  expression: "'sixthDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  100,
                                                  "6th day rate"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "sixthDay",
                                                        expression:
                                                          "'sixthDay'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.sixthDay,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "sixthDay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.sixthDay",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("percent", {
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showDefaultPayCode && _vm.showSixthDayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          style: `margin:${
                                            _vm.$screen.width <= 992
                                              ? "0"
                                              : "6px 0 0 0"
                                          }`,
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _vm.$screen.width > 992
                                                ? _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "seventhDayPayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  )
                                                : _vm._e(),
                                              _c("payroll-code", {
                                                ref: _vm.generateTextId(
                                                  "seventhDayPayCode"
                                                ),
                                                attrs: {
                                                  "style-type": "select",
                                                  value:
                                                    _vm.contract
                                                      .seventhDayPayCode,
                                                },
                                                on: {
                                                  "payroll-code:input":
                                                    function ($event) {
                                                      return _vm.setPayroolCodeValue(
                                                        $event,
                                                        "seventhDayPayCode"
                                                      )
                                                    },
                                                  "payroll-code:reset":
                                                    function ($event) {
                                                      return _vm.resetPayroolCodeValue(
                                                        $event,
                                                        "seventhDayPayCode"
                                                      )
                                                    },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showSeventhDayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${
                                              (!_vm.showSixthDayRate &&
                                                !_vm.showSundayRate) ||
                                              (!_vm.showSixthDayRate &&
                                                _vm.showSundayRate) ||
                                              (_vm.showSixthDayRate &&
                                                !_vm.showSundayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                            xl: `${
                                              (!_vm.showSixthDayRate &&
                                                !_vm.showSundayRate) ||
                                              (!_vm.showSixthDayRate &&
                                                _vm.showSundayRate) ||
                                              (_vm.showSixthDayRate &&
                                                !_vm.showSundayRate)
                                                ? _vm.showDefaultPayCode
                                                  ? 3
                                                  : 6
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 4
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "seventhDay",
                                                  expression: "'seventhDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  101,
                                                  "7th day rate"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "seventhDay",
                                                        expression:
                                                          "'seventhDay'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.seventhDay,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "seventhDay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.seventhDay",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("percent", {
                                                            staticClass: "icon",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showDefaultPayCode &&
                                  _vm.showSeventhDayRate
                                    ? _c(
                                        "b-col",
                                        {
                                          style: `margin:${
                                            _vm.$screen.width <= 992
                                              ? "0"
                                              : "6px 0 0 0"
                                          }`,
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _vm.$screen.width > 992
                                                ? _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "sixthDayPayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  )
                                                : _vm._e(),
                                              _c("payroll-code", {
                                                ref: _vm.generateTextId(
                                                  "sixthDayPayCode"
                                                ),
                                                attrs: {
                                                  "style-type": "select",
                                                  value:
                                                    _vm.contract
                                                      .sixthDayPayCode,
                                                },
                                                on: {
                                                  "payroll-code:input":
                                                    function ($event) {
                                                      return _vm.setPayroolCodeValue(
                                                        $event,
                                                        "sixthDayPayCode"
                                                      )
                                                    },
                                                  "payroll-code:reset":
                                                    function ($event) {
                                                      return _vm.resetPayroolCodeValue(
                                                        $event,
                                                        "sixthDayPayCode"
                                                      )
                                                    },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              329,
                                              "Public holiday calendar"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.publicHolidays,
                                            },
                                            model: {
                                              value:
                                                _vm.contract
                                                  .publicHolidayCalendar,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "publicHolidayCalendar",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.publicHolidayCalendar",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showLunch
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader("lunch")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.UTENSIL.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.UTENSIL.color,
                                    size: 22,
                                  },
                                }),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(171, "Lunch"))),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["lunch"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["lunch"].isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showLunch
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible: _vm.configAccordion["lunch"].isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "lunchMinimum",
                                              expression: "'lunchMinimum'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              117,
                                              "Min lunch"
                                            ),
                                          },
                                        },
                                        [
                                          _c("input-date-time-picker", {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                117,
                                                "Min lunch"
                                              ),
                                              uiid: "wbnh",
                                              type: "text",
                                              placeholder: "hh:mm",
                                              isSubmitted: _vm.isSubmitted,
                                              item: _vm.contract
                                                .lunchMinimumStr,
                                              nbHour:
                                                _vm.rangeHour[
                                                  _vm.WORKTYPE.daily
                                                ],
                                              nbMinute:
                                                _vm.rangeMinute[
                                                  _vm.WORKTYPE.daily
                                                ],
                                              isEditable: () =>
                                                _vm.isEditable(),
                                              fieldName: "lunchMinimum",
                                              workType: _vm.WORKTYPE.daily,
                                            },
                                            on: {
                                              change: _vm.handleChangeHourMin,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showStandard()
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value:
                                                    "lunchMinimumForContDay",
                                                  expression:
                                                    "'lunchMinimumForContDay'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  217,
                                                  "Min lunch for continuous day"
                                                ),
                                              },
                                            },
                                            [
                                              _c("input-date-time-picker", {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    217,
                                                    "Min lunch for continuous day"
                                                  ),
                                                  uiid: "wbnh",
                                                  type: "text",
                                                  placeholder: "hh:mm",
                                                  isSubmitted: _vm.isSubmitted,
                                                  item: _vm.contract
                                                    .lunchMinimumForContDayStr,
                                                  nbHour:
                                                    _vm.rangeHour[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  nbMinute:
                                                    _vm.rangeMinute[
                                                      _vm.WORKTYPE.daily
                                                    ],
                                                  isEditable: () =>
                                                    _vm.isEditable(),
                                                  fieldName:
                                                    "lunchMinimumForContDay",
                                                  workType: _vm.WORKTYPE.daily,
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeHourMin,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showBeforeCallTime
                    ? _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleClickHeader(
                                  "before-call-time-rate"
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "title w-90" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.ALARM_CHECK.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.ALARM_CHECK.color,
                                      size: 22,
                                    },
                                  }
                                ),
                                _c("div", { staticClass: "fz-1-35 ml-3" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.showStandard()
                                          ? _vm.FormMSG(
                                              172,
                                              "Before call time rate"
                                            )
                                          : _vm.FormMSG(
                                              198,
                                              "Early shooting day rate"
                                            )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer d-flex justify-content-end w-10",
                              },
                              [
                                !_vm.configAccordion["before-call-time-rate"]
                                  .isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_DOWN.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_DOWN.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm.configAccordion["before-call-time-rate"]
                                  .isVisible
                                  ? _c(
                                      _vm.getLucideIcon(
                                        _vm.ICONS.CHEVRON_UP.name
                                      ),
                                      {
                                        tag: "component",
                                        attrs: {
                                          color: _vm.ICONS.CHEVRON_UP.color,
                                          size: 22,
                                        },
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.showBeforeCallTime
                    ? _c(
                        "b-collapse",
                        {
                          attrs: {
                            visible:
                              _vm.configAccordion["before-call-time-rate"]
                                .isVisible,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-card-collapsible inside" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "sub-title-layout pb-3",
                                  staticStyle: {
                                    "font-size": "0.9rem",
                                    color: "rgba(6, 38, 62, 0.64)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          173,
                                          "You can use this section to define a specific rate for the crew members who start to work before the call time during a shooting day."
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                        xl: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "beforeCallTimeRate",
                                              expression:
                                                "'beforeCallTimeRate'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              174,
                                              "Before call time rate"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "beforeCallTimeRate",
                                                    expression:
                                                      "'beforeCallTimeRate'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "number",
                                                  step: "0.000001",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .beforeCallTimeRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "beforeCallTimeRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.beforeCallTimeRate",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("percent", {
                                                        staticClass: "icon",
                                                        attrs: {
                                                          color: "#06263E",
                                                          size: 16,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.showStandard()
                                            ? _c(
                                                "div",
                                                { staticClass: "info-message" },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("info", {
                                                        attrs: { size: 16 },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#00a09c",
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.calculateAverageRate(
                                                              _vm.contract
                                                                .hourlyRate,
                                                              _vm.contract
                                                                .beforeCallTimeRate
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.currencySymbol
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              176,
                                                              "per hour"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex align-items-center mb-2",
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                        xl: `${_vm.showDefaultPayCode ? 4 : 6}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            disabled:
                                              _vm.isEditable() === false,
                                            size: "lg",
                                          },
                                          model: {
                                            value:
                                              _vm.contract
                                                .ifWorkAboveDailyHours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contract,
                                                "ifWorkAboveDailyHours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contract.ifWorkAboveDailyHours",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                177,
                                                "Applies if worked hours above daily hours"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.showDefaultPayCode
                                    ? _c(
                                        "b-col",
                                        {
                                          style: `margin:${
                                            _vm.$screen.width <= 576
                                              ? "0"
                                              : "6px 0 0 0"
                                          }`,
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _vm.$screen.width > 576
                                            ? _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "beforeTimePayCode",
                                                  },
                                                },
                                                [_vm._v(" ")]
                                              )
                                            : _vm._e(),
                                          _c("payroll-code", {
                                            ref: _vm.generateTextId(
                                              "beforeTimePayCode"
                                            ),
                                            attrs: {
                                              "style-type": "select",
                                              value:
                                                _vm.contract.beforeTimePayCode,
                                            },
                                            on: {
                                              "payroll-code:input": function (
                                                $event
                                              ) {
                                                return _vm.setPayroolCodeValue(
                                                  $event,
                                                  "beforeTimePayCode"
                                                )
                                              },
                                              "payroll-code:reset": function (
                                                $event
                                              ) {
                                                return _vm.resetPayroolCodeValue(
                                                  $event,
                                                  "beforeTimePayCode"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickHeader("allowances")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "title w-90" },
                          [
                            _c(_vm.getLucideIcon(_vm.ICONS.AWARD.name), {
                              tag: "component",
                              attrs: { color: _vm.ICONS.AWARD.color, size: 22 },
                            }),
                            _c("div", { staticClass: "fz-1-35 ml-3" }, [
                              _vm._v(_vm._s(_vm.FormMSG(178, "Allowances"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "cursor-pointer d-flex justify-content-end w-10",
                          },
                          [
                            !_vm.configAccordion["allowances"].isVisible
                              ? _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.CHEVRON_DOWN.color,
                                      size: 22,
                                    },
                                  }
                                )
                              : _vm._e(),
                            _vm.configAccordion["allowances"].isVisible
                              ? _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.CHEVRON_UP.color,
                                      size: 22,
                                    },
                                  }
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible: _vm.configAccordion["allowances"].isVisible,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible inside" },
                        [
                          _c("div", { staticClass: "sub-title-layout pb-3" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(179, "General allowances")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "kmRate"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract.useTravelDailyRate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "useTravelDailyRate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.useTravelDailyRate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 5
                                                : 10
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 5
                                                : 10
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576 ? 0 : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576 ? 0 : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 10 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "kmRate",
                                                  expression: "'kmRate'",
                                                },
                                              ],
                                              attrs: {
                                                label: `${_vm.FormMSG(
                                                  107,
                                                  "Travel rate"
                                                )} (/${_vm.distanceUnit})`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "kmRate",
                                                        expression: "'kmRate'",
                                                      },
                                                    ],
                                                    ref: "kmRate",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useTravelDailyRate ===
                                                          false,
                                                      type: "number",
                                                      step: "0.0001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.kmRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "kmRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.kmRate",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "travelAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "travelAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .travelAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "travelAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "travelAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : !_vm.showDefaultPayCode &&
                                          _vm.$screen.width > 576
                                        ? _c("b-col", {
                                            attrs: {
                                              sm: `${
                                                _vm.$screen.width <= 576 ? 0 : 5
                                              }`,
                                              md: `${
                                                _vm.$screen.width <= 576 ? 0 : 5
                                              }`,
                                              lg: `${
                                                _vm.$screen.width <= 576 ? 0 : 5
                                              }`,
                                              xl: `${
                                                _vm.$screen.width <= 576 ? 0 : 5
                                              }`,
                                              cols: `${
                                                _vm.$screen.width <= 576 ? 5 : 0
                                              }`,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "carAllowance"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract.useCarAllowance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "useCarAllowance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.useCarAllowance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "allowanceCar",
                                                  expression: "'allowanceCar'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(180, "Car"),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "allowanceCar",
                                                        expression:
                                                          "'allowanceCar'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useCarAllowance ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .carAllowance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "carAllowance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.carAllowance",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { margin: "6px 0 0 0" },
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "allowanceCarType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.periodicalOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .carAllowanceRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "carAllowanceRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.carAllowanceRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : _vm.showDefaultPayCode
                                                    ? 4
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "carAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "carAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .carAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "carAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "carAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "phoneAllowance"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract.usePhoneAllowance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "usePhoneAllowance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.usePhoneAllowance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "allowancePhone",
                                                  expression:
                                                    "'allowancePhone'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  181,
                                                  "Phone"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "allowancePhone",
                                                        expression:
                                                          "'allowancePhone'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .usePhoneAllowance ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .phoneAllowance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "phoneAllowance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.phoneAllowance",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { margin: "6px 0 0 0" },
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "allowancePhoneType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.periodicalOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .phoneAllowanceRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "phoneAllowanceRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.phoneAllowanceRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : _vm.showDefaultPayCode
                                                    ? 4
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "phoneAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "phoneAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .phoneAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "phoneAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "phoneAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "computerAllowance"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract
                                                  .useComputerAllowance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "useComputerAllowance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.useComputerAllowance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "allowanceComputer",
                                                  expression:
                                                    "'allowanceComputer'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  182,
                                                  "Computer"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "allowanceComputer",
                                                        expression:
                                                          "'allowanceComputer'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useComputerAllowance ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .computerAllowance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "computerAllowance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.computerAllowance",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { margin: "6px 0 0 0" },
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "allowanceComputerType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.periodicalOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .computerAllowanceRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "computerAllowanceRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.computerAllowanceRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : _vm.showDefaultPayCode
                                                    ? 4
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "computerAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "computerAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .computerAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "computerAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "computerAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "boxKitAllowance"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract.useBoxKitAllowance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "useBoxKitAllowance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.useBoxKitAllowance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "allowanceBoxKit",
                                                  expression:
                                                    "'allowanceBoxKit'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  183,
                                                  "Box kit"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "allowanceBoxKit",
                                                        expression:
                                                          "'allowanceBoxKit'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useBoxKitAllowance ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .boxKitAllowance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "boxKitAllowance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.boxKitAllowance",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { margin: "6px 0 0 0" },
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "allowanceBoxKitType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.periodicalOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .boxKitAllowanceRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "boxKitAllowanceRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.boxKitAllowanceRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : _vm.showDefaultPayCode
                                                    ? 4
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "boxKitAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "boxKitAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .boxKitAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "boxKitAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "boxKitAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          class: `${
                                            _vm.$screen.width <= 576
                                              ? "mt-3"
                                              : ""
                                          }`,
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            staticClass: "fix-check-allowance",
                                            attrs: {
                                              disabled:
                                                _vm.isEditable() === false,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessGeneralAllowance(
                                                  $event,
                                                  "productionFeeAllowance"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.contract
                                                  .useProductionFeeAllowance,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "useProductionFeeAllowance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.useProductionFeeAllowance",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value:
                                                    "productionFeeAllowance",
                                                  expression:
                                                    "'productionFeeAllowance'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  184,
                                                  "Production fee"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value:
                                                          "productionFeeAllowance",
                                                        expression:
                                                          "'productionFeeAllowance'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                          false ||
                                                        _vm.contract
                                                          .useProductionFeeAllowance ===
                                                          false,
                                                      type: "number",
                                                      step: "0.000001",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .productionFeeAllowance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "productionFeeAllowance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.productionFeeAllowance",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { margin: "6px 0 0 0" },
                                          attrs: {
                                            sm: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            md: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            lg: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            xl: `${
                                              _vm.$screen.width <= 576
                                                ? 0
                                                : _vm.showDefaultPayCode
                                                ? 3
                                                : 5
                                            }`,
                                            cols: `${
                                              _vm.$screen.width <= 576 ? 5 : 0
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "productionFeeAllowanceType",
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  options:
                                                    _vm.periodicalOptions,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .productionFeeAllowanceRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "productionFeeAllowanceRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.productionFeeAllowanceRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.$screen.width <= 576
                                        ? _c("b-col", { attrs: { cols: "2" } })
                                        : _vm._e(),
                                      _vm.showDefaultPayCode
                                        ? _c(
                                            "b-col",
                                            {
                                              style: `margin:${
                                                _vm.$screen.width <= 576
                                                  ? "-20px 0 0 0"
                                                  : "6px 0 0 0"
                                              }`,
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : _vm.showDefaultPayCode
                                                    ? 4
                                                    : 5
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "productionFeeAllowancePayCode",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c("payroll-code", {
                                                    ref: _vm.generateTextId(
                                                      "productionFeeAllowancePayCode"
                                                    ),
                                                    attrs: {
                                                      "style-type": "select",
                                                      value:
                                                        _vm.contract
                                                          .productionFeeAllowancePayCode,
                                                    },
                                                    on: {
                                                      "payroll-code:input":
                                                        function ($event) {
                                                          return _vm.setPayroolCodeValue(
                                                            $event,
                                                            "productionFeeAllowancePayCode"
                                                          )
                                                        },
                                                      "payroll-code:reset":
                                                        function ($event) {
                                                          return _vm.resetPayroolCodeValue(
                                                            $event,
                                                            "productionFeeAllowancePayCode"
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "divider" }),
                          _vm.showLunchPerDiem ||
                          _vm.showHotelPerDiem ||
                          _vm.showDinnerPerDiem ||
                          _vm.showAbroadPerDiem
                            ? _c(
                                "div",
                                { staticClass: "sub-title-layout pb-3" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(185, "Per diem allowances")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _vm.showLunchPerDiem
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex justify-content-end align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "mt-3"
                                                  : ""
                                              }`,
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                staticClass:
                                                  "fix-check-allowance",
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  size: "lg",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleAccessGeneralAllowance(
                                                      $event,
                                                      "lunchPerDiem"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .useLunchPerDiem,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "useLunchPerDiem",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.useLunchPerDiem",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                md: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                lg: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                xl: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : 0
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "lunchPerDiem",
                                                      expression:
                                                        "'lunchPerDiem'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      108,
                                                      "Lunch per diem"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "lunchPerDiem",
                                                            expression:
                                                              "'lunchPerDiem'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                              false ||
                                                            _vm.contract
                                                              .useLunchPerDiem ===
                                                              false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .lunchPerDiem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "lunchPerDiem",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.lunchPerDiem",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.$screen.width <= 576
                                            ? _c("b-col", {
                                                attrs: { cols: "2" },
                                              })
                                            : _vm._e(),
                                          _vm.showDefaultPayCode
                                            ? _c(
                                                "b-col",
                                                {
                                                  style: `margin:${
                                                    _vm.$screen.width <= 576
                                                      ? "-20px 0 0 0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                  attrs: {
                                                    cols: `${
                                                      _vm.$screen.width <= 576
                                                        ? 10
                                                        : _vm.showDefaultPayCode
                                                        ? 5
                                                        : 10
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "lunchPerDiemPayCode",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "lunchPerDiemPayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .lunchPerDiemPayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "lunchPerDiemPayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "lunchPerDiemPayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showDinnerPerDiem
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex justify-content-end align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "mt-3"
                                                  : ""
                                              }`,
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                staticClass:
                                                  "fix-check-allowance",
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  size: "lg",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleAccessGeneralAllowance(
                                                      $event,
                                                      "dinnerPerDiem"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .useDinnerPerDiem,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "useDinnerPerDiem",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.useDinnerPerDiem",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                md: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                lg: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                xl: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : 0
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "dinerPerDiem",
                                                      expression:
                                                        "'dinerPerDiem'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      186,
                                                      "Diner per diem"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "dinerPerDiem",
                                                            expression:
                                                              "'dinerPerDiem'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                              false ||
                                                            _vm.contract
                                                              .useDinnerPerDiem ===
                                                              false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .dinnerPerDiem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "dinnerPerDiem",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.dinnerPerDiem",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.$screen.width <= 576
                                            ? _c("b-col", {
                                                attrs: { cols: "2" },
                                              })
                                            : _vm._e(),
                                          _vm.showDefaultPayCode
                                            ? _c(
                                                "b-col",
                                                {
                                                  style: `margin:${
                                                    _vm.$screen.width <= 576
                                                      ? "-20px 0 0 0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                  attrs: {
                                                    cols: `${
                                                      _vm.$screen.width <= 576
                                                        ? 10
                                                        : _vm.showDefaultPayCode
                                                        ? 5
                                                        : 10
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "dinerPerDiemPayCode",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "dinerPerDiemPayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .dinerPerDiemPayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "dinerPerDiemPayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "dinerPerDiemPayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _vm.showHotelPerDiem
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex justify-content-end align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "mt-3"
                                                  : ""
                                              }`,
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                staticClass:
                                                  "fix-check-allowance",
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  size: "lg",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleAccessGeneralAllowance(
                                                      $event,
                                                      "hotelPerDiem"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .useHotelPerDiem,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "useHotelPerDiem",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.useHotelPerDiem",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                md: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                lg: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                xl: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : 0
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "hotelPerDiem",
                                                      expression:
                                                        "'hotelPerDiem'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      109,
                                                      "Hotel per diem"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "hotelPerDiem",
                                                            expression:
                                                              "'hotelPerDiem'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                              false ||
                                                            _vm.contract
                                                              .useHotelPerDiem ===
                                                              false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .hotelPerDiem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "hotelPerDiem",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.hotelPerDiem",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.$screen.width <= 576
                                            ? _c("b-col", {
                                                attrs: { cols: "2" },
                                              })
                                            : _vm._e(),
                                          _vm.showDefaultPayCode
                                            ? _c(
                                                "b-col",
                                                {
                                                  style: `margin:${
                                                    _vm.$screen.width <= 576
                                                      ? "-20px 0 0 0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                  attrs: {
                                                    cols: `${
                                                      _vm.$screen.width <= 576
                                                        ? 10
                                                        : _vm.showDefaultPayCode
                                                        ? 5
                                                        : 10
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "hotelPerDiemPayCode",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "hotelPerDiemPayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .hotelPerDiemPayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "hotelPerDiemPayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "hotelPerDiemPayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showAbroadPerDiem
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex justify-content-end align-items-center",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? "mt-3"
                                                  : ""
                                              }`,
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                staticClass:
                                                  "fix-check-allowance",
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  size: "lg",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleAccessGeneralAllowance(
                                                      $event,
                                                      "abroadPerDiem"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .useAbroadPerDiem,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "useAbroadPerDiem",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.useAbroadPerDiem",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                md: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                lg: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                xl: `${
                                                  _vm.$screen.width <= 576
                                                    ? 0
                                                    : _vm.showDefaultPayCode
                                                    ? 5
                                                    : 10
                                                }`,
                                                cols: `${
                                                  _vm.$screen.width <= 576
                                                    ? 10
                                                    : 0
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "abroadPerDiem",
                                                      expression:
                                                        "'abroadPerDiem'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      187,
                                                      "Abroad per diem"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        directives: [
                                                          {
                                                            name: "uni-id",
                                                            rawName: "v-uni-id",
                                                            value:
                                                              "abroadPerDiem",
                                                            expression:
                                                              "'abroadPerDiem'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                              false ||
                                                            _vm.contract
                                                              .useAbroadPerDiem ===
                                                              false,
                                                          type: "number",
                                                          step: "0.000001",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .abroadPerDiem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "abroadPerDiem",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.abroadPerDiem",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                "currency-svg",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#06263E",
                                                                    opacity:
                                                                      "0.85",
                                                                    width: "15",
                                                                    height:
                                                                      "15",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.$screen.width <= 576
                                            ? _c("b-col", {
                                                attrs: { cols: "2" },
                                              })
                                            : _vm._e(),
                                          _vm.showDefaultPayCode
                                            ? _c(
                                                "b-col",
                                                {
                                                  style: `margin:${
                                                    _vm.$screen.width <= 576
                                                      ? "-20px 0 0 0"
                                                      : "6px 0 0 0"
                                                  }`,
                                                  attrs: {
                                                    cols: `${
                                                      _vm.$screen.width <= 576
                                                        ? 10
                                                        : _vm.showDefaultPayCode
                                                        ? 5
                                                        : 10
                                                    }`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "abroadPerDiemPayCode",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("payroll-code", {
                                                        ref: _vm.generateTextId(
                                                          "abroadPerDiemPayCode"
                                                        ),
                                                        attrs: {
                                                          "style-type":
                                                            "select",
                                                          value:
                                                            _vm.contract
                                                              .abroadPerDiemPayCode,
                                                        },
                                                        on: {
                                                          "payroll-code:input":
                                                            function ($event) {
                                                              return _vm.setPayroolCodeValue(
                                                                $event,
                                                                "abroadPerDiemPayCode"
                                                              )
                                                            },
                                                          "payroll-code:reset":
                                                            function ($event) {
                                                              return _vm.resetPayroolCodeValue(
                                                                $event,
                                                                "abroadPerDiemPayCode"
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "card-collapsible px-0 mt-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickHeader("note")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "title w-90" },
                          [
                            _c(_vm.getLucideIcon(_vm.ICONS.MENU.name), {
                              tag: "component",
                              attrs: { color: _vm.ICONS.MENU.color, size: 22 },
                            }),
                            _c("div", { staticClass: "fz-1-35 ml-3" }, [
                              _vm._v(_vm._s(_vm.FormMSG(188, "Note"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "cursor-pointer d-flex justify-content-end w-10",
                          },
                          [
                            !_vm.configAccordion["note"].isVisible
                              ? _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.CHEVRON_DOWN.color,
                                      size: 22,
                                    },
                                  }
                                )
                              : _vm._e(),
                            _vm.configAccordion["note"].isVisible
                              ? _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.CHEVRON_UP.color,
                                      size: 22,
                                    },
                                  }
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "b-collapse",
                    {
                      attrs: { visible: _vm.configAccordion["note"].isVisible },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible inside" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: { sm: "3", md: "3", lg: "3", xl: "3" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "contractType",
                                                  expression: "'contractType'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  304,
                                                  "Contract type"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "contractType",
                                                    expression:
                                                      "'contractType'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "text",
                                                  autocomplete: "off",
                                                  placeholder: _vm.FormMSG(
                                                    305,
                                                    "Your contract type ..."
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract.contractType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "contractType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.contractType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "misc",
                                                  expression: "'misc'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  296,
                                                  "Misc."
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "misc",
                                                    expression: "'misc'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "text",
                                                  autocomplete: "off",
                                                  placeholder: _vm.FormMSG(
                                                    297,
                                                    "Your contract reference ..."
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .contractReference,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "contractReference",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.contractReference",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "collectiveAgreement",
                                                  expression:
                                                    "'collectiveAgreement'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  314,
                                                  "Collective agreement code"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value:
                                                      "collectiveAgreement",
                                                    expression:
                                                      "'collectiveAgreement'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "text",
                                                  autocomplete: "off",
                                                  placeholder: _vm.FormMSG(
                                                    315,
                                                    "Your collective agreement code ..."
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract
                                                      .collectiveAgreement,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "collectiveAgreement",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.collectiveAgreement",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "echelon",
                                                  expression: "'echelon'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  316,
                                                  "Echelon"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "echelon",
                                                    expression: "'echelon'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "text",
                                                  autocomplete: "off",
                                                  placeholder: _vm.FormMSG(
                                                    317,
                                                    "Your echelon ..."
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.contract.echelon,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "echelon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.echelon",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "analyticCode",
                                                  expression: "'analyticCode'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  318,
                                                  "Analytic code"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "uni-id",
                                                    rawName: "v-uni-id",
                                                    value: "analyticCode",
                                                    expression:
                                                      "'analyticCode'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.isEditable() === false,
                                                  type: "text",
                                                  autocomplete: "off",
                                                  placeholder: _vm.FormMSG(
                                                    319,
                                                    "Your analytic code ..."
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.contract.analyticCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contract,
                                                      "analyticCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contract.analyticCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: { sm: "9", md: "9", lg: "9", xl: "9" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      directives: [
                                        {
                                          name: "uni-for",
                                          rawName: "v-uni-for",
                                          value: "note",
                                          expression: "'note'",
                                        },
                                      ],
                                    },
                                    [
                                      _c("b-form-textarea", {
                                        staticClass: "pb-2",
                                        attrs: {
                                          disabled: _vm.isEditable() === false,
                                          type: "textarea",
                                          rows: `${
                                            _vm.$screen.width <= 576 ? 6 : 17
                                          }`,
                                          placeholder: _vm.FormMSG(
                                            189,
                                            "Enter a note here ..."
                                          ),
                                          autocomplete: "off",
                                          id: "note",
                                        },
                                        model: {
                                          value: _vm.contract.note,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.contract, "note", $$v)
                                          },
                                          expression: "contract.note",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.canEditCustomField
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("div", { staticClass: "divider" }),
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(330, "Custom fields")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "pb-2" },
                                          _vm._l(
                                            _vm.customFields,
                                            function (customField, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "d-flex mb-2 w-100",
                                                  attrs: {
                                                    xl: _vm.sizeFlagInput(
                                                      _vm.customFields.length
                                                    ),
                                                    lg: _vm.sizeFlagInput(
                                                      _vm.customFields.length
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("custom-field-template", {
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      field: customField,
                                                      "name-model":
                                                        _vm.customFieldModels[
                                                          index
                                                        ],
                                                      "name-group":
                                                        customField.nameGroup,
                                                      index: index,
                                                    },
                                                    on: {
                                                      "custom-field-template:payload":
                                                        _vm.onFieldTemplateChanged,
                                                    },
                                                    model: {
                                                      value:
                                                        customField[
                                                          _vm.customFieldModels[
                                                            index
                                                          ]
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          customField,
                                                          _vm.customFieldModels[
                                                            index
                                                          ],
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "customField[customFieldModels[index]]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isEditable()
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-collapsible pb-1",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "w-270-px d-flex justify-content-center align-items-center",
                                                    attrs: {
                                                      size: "md",
                                                      variant:
                                                        "outline-primary",
                                                      block: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.manageCustomFields,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "PlusCircle"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          size: 20,
                                                          "stroke-width": 2.5,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "px-2",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "0px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                331,
                                                                "Add/Edit Custom Fields"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.isFilmSingleUserFree && !_vm.isFilmSingleUser && !_vm.hideActions
        ? _c(
            "div",
            { staticClass: "px-4 pt-3" },
            [
              _c(
                "b-row",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                  class: `${_vm.$screen.width <= 576 ? "footer-fixed" : ""}`,
                },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "px-4",
                          attrs: {
                            size: "md",
                            variant: "outline-secondary",
                            block: "",
                          },
                          on: { click: _vm.copyContract },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(192, "Copy deal memo")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      class: `${_vm.$screen.width <= 992 ? "my-2" : ""}`,
                      attrs: { sm: "12", md: "12", lg: "4", xl: "4" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "px-4",
                          attrs: {
                            size: "md",
                            variant: "outline-secondary",
                            disabled: _vm.contractCopied === 0,
                            block: "",
                          },
                          on: { click: _vm.pasteContract },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(193, "Paste deal memo")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "px-4",
                          attrs: {
                            size: "md",
                            variant: "outline-secondary",
                            disabled: _vm.disableSaveDealMemoTemplate,
                            block: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveAsDealMemoTemplate($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(194, "Save as deal memo template")
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("custom-field-modal", {
        ref: "contractCustomField",
        attrs: { "parent-type": _vm.defaultCustomFieldParentType },
        on: {
          "custom-field-main-modal:closed": _vm.onCustomFieldMainModalClosed,
          "custom-field-main-modal:reload": _vm.onCustomFieldMainModalReloaded,
        },
        model: {
          value: _vm.isCustomFieldModalOpen,
          callback: function ($$v) {
            _vm.isCustomFieldModalOpen = $$v
          },
          expression: "isCustomFieldModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }